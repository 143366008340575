// @ts-nocheck
import { hookstate, useHookstate } from "@hookstate/core";
import { Alert, Backdrop, Button, Snackbar } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useIdleTimer } from "react-idle-timer";
import {
  userInfoState
} from "../drive-feature/data-access/state";
import AuthService from "../Service/Auth";
import { useEffect } from "react";
const GENERAL_DEBOUNCE_TIME = 300;
export const alertIdle = hookstate({
  show: false,
  type: "error",
  message: "",
  anchorOrigin: {
    vertical: "top",
    horizontal: "center",
  },
});
export default function IdleTimerComponent() {
  const { t } = useTranslation("common");
  const userInfo = useHookstate(userInfoState);

  const showAlert = useHookstate(alertIdle);
  const idleTimer = useIdleTimer({
    promptBeforeIdle: 50,
    events: [
      "mousemove",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove",
      "visibilitychange",
      "focus",
    ],
    onActive: () => {
      //   console.log("heheACctive");
    },
    onPrompt: () => {
      //   console.log("hehehehehe");
    },
    onAction: () => {
      //   console.log("action");
    },
    startOnMount: false,
    startManually: true,
    timeout: (userInfo.timeout_idle.get() || 10080) * 1000 * 60, // time in millisecond
    onIdle: async () => {
      showAlert.show.set(true);
      showAlert.message.set(t("general.alert.user-idle-timed-out"));

      if ("serviceWorker" in navigator) {
        navigator.serviceWorker.ready.then((registration) => {
          if (registration?.active) {
            // registration.dispatchEvent
            registration.active.postMessage("cache-clear-logout");
          }
        });
      }
      localStorage.clear();
    },
    crossTab: true,
    leaderElection: true,
    debounce: GENERAL_DEBOUNCE_TIME, // time in millisecond
  });
  useEffect(() => {
    if(idleTimer){
      idleTimer.start();
    }
  }, [userInfo])
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: 10004}}
        open={showAlert.show.get()}
      ></Backdrop>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        className="snackbar_on_idle"
        open={showAlert.show.get()}
        // autoHideDuration={10000}
        onClose={async (e, reason) => {
          console.log(reason);
          if (reason == "clickaway") return;
        }}
        sx={{
          zIndex: 10005,
        }}
      >
        <Alert
          className="alert_on_idle"
          variant="filled"
          severity={showAlert.type.get() || "error"}
          action={
            <Button
              className="navigate_btn_on_idle"
              onClick={async () => {
                showAlert.show.set(false);
                // if (await AuthService.GetCurrentSession()) {
                localStorage.removeItem("user-info");
                userInfoState.set(null);
                AuthService.Logout();
                // }
              }}
            >
              {t("general.button.navigate-login-on-idle")}
            </Button>
          }
        >
          {showAlert.message.get()}
        </Alert>
      </Snackbar>
    </>
  );
}
