// @ts-nocheck
import axios from 'axios';
import Auth from "./Auth";
import API from '../Config/API';
import CryptoJS from 'crypto-js';
import { currencyOptionsState, userInfoState } from '../drive-feature/data-access/state';
import { GetUserInfoNew } from '../data-access/api';

const Service = class {

	static TemporaryTokenVerification = async (token) => {
		const uri = API.url + "/token/verify.json";

		return await new Promise((resolve, reject) => {
			axios.get(uri, {
				headers: {
					Authorization: token,
				},
			}).then(resp => {
				resolve(resp);
			}).catch(error => {
				reject(error);
			});
		});

	}

	static deleteUserControl = async (userEmail = null) => {
		let email = '';

		if (userEmail !== null) {
			email = userEmail;
		} else {
			email = await Auth.GetUserAttributes("email");
		}

		const tokenID = await Auth.GetTokenID();
		const uri = API.url + "/logincontrol/deleteItem.json";

		return await new Promise((resolve, reject) => {
			let queryParams = new URLSearchParams();
			queryParams.set("mail", CryptoJS.MD5(email).toString());
			let url = uri + "?" + queryParams.toString();

			axios.get(url, {
				headers: {
					Authorization: tokenID,
				},
			}).then(resp => {
				resolve(resp);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static getUserControl = async (email, token) => {
		const uri = API.url + "/logincontrol/getItem.json";

		return await new Promise((resolve, reject) => {
			let queryParams = new URLSearchParams();
			queryParams.set("mail", CryptoJS.MD5(email).toString());
			let url = uri + "?" + queryParams.toString();

			axios.get(url, {
				headers: {
					Authorization: token,
				},
			}).then(resp => {
				resolve(resp);
			}).catch(error => {
				reject(error);
			});
		});

	}

	static SaveUserInfoToLocalStorage = (userInfo) => {
		localStorage.setItem("user-info", JSON.stringify(userInfo));
	}

	static GetUserInfo = async (force = false) => {

		// const tokenS = new Date().getTime() / 1000;

		// const uri = API.url + "/user/info.json";
		// const email = await Auth.GetUserAttributes("email");
		// const tokenID = await Auth.GetTokenID();
		const userCache = userInfoState.get({ stealth: true, noproxy: true });
		// console.log('GetUserInfo', userCache);

		// console.log(userCache);
		if (userCache) {
			// console.log('GetUserInfoStartChache',(new Date().getTime() / 1000) - tokenS );
			return userCache;
		}
		return await new Promise((resolve, reject) => {
			GetUserInfoNew().then(res => {
				resolve(res);
			}).catch((error) => {
				reject(error);
			});
		});


		// const userControlData = await this.getUserControl(email, tokenID).catch((err) => {
		// 	force = true;
		// });

		// if (!force && userControlData && userControlData.data.mail !== '' && userCache) {
		// 	return JSON.parse(decodeURIComponent(userControlData.data.body));
		// }
		// // const tokenE = new Date().getTime() / 1000;
		// // console.log('GetUserInfoStart',tokenE - tokenS );

		// return await new Promise((resolve, reject) => {
		// 	if (!email) {
		// 		reject(false);
		// 	}
		// 	// console.log(tokenID);
		// 	let queryParams = new URLSearchParams();
		// 	queryParams.set("email", email);
		// 	let url = uri + "?" + queryParams.toString();
		// 	axios.get(url, {
		// 		headers: {
		// 			Authorization: tokenID,
		// 		},
		// 	}).then((resp) => {
		// 		let userinfoMap = {};
		// 		console.log(resp.data.language);

		// 		localStorage.setItem("user-info", JSON.stringify(resp.data.payload));
		// 		userInfoState.set(v=>({
		// 			...v,
		// 			...resp.data.payload,
		// 		}));
		// 		for (let [key, value] of Object.entries(resp.data.payload)) {
		// 			if (['profile_image'].includes(key)) {
		// 				localStorage.setItem(key, value);
		// 				continue;
		// 			}
		// 			userinfoMap[key] = value;
		// 		}

		// 		// console.log(userinfoMap);
		// 		// const putUri = API.url + "/logincontrol/putItem.json";
		// 		// let putQueryParams = new URLSearchParams();
		// 		// putQueryParams.set("mail", CryptoJS.MD5(email).toString());
		// 		// putQueryParams.set("body", encodeURIComponent(JSON.stringify(userinfoMap)));
		// 		// let putUrl = putUri + "?" + putQueryParams.toString();
		// 		// axios.get(putUrl, {
		// 		// 	headers: {
		// 		// 		Authorization: tokenID,
		// 		// 	},
		// 		// }).then((resp) => {
		// 		// 	resolve(resp);
		// 		// }).catch((error) => {
		// 		// 	reject(error);
		// 		// });
		// 		// console.log('GetUserInfoEnd',tokenE - new Date().getTime()/ 1000 );
		// 		resolve(resp.data.payload);

		// 	}).catch((error) => {
		// 		reject(error);
		// 	});
		// });
	}

	/** Update UserInfo */
	static SetUserInfo = async (data) => {
		const url = new URL(API.url + "/user/info.json");
		const tokenID = await Auth.GetTokenID();

		/* */
		return await new Promise((resolve, reject) => {
			if (!tokenID) {
				reject("token.get-fail");
			}
			axios.post(url, data, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	static GetDocumentStoragePath = async (path, type) => {
		const uri = API.url + "/document/getPath.json";
		const tokenID = await Auth.GetTokenID();
		if (!tokenID) {
			return false;
		}

		let url = new URL(uri);
		url.searchParams.set("path", path);
		url.searchParams.set("type", type);
		url = url.toString();

		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static GetFileboxStoragePath = async (path, type, linkage) => {
		const uri = API.url + "/filebox/getPath.json";
		const tokenID = await Auth.GetTokenID();
		if (!tokenID) {
			return false;
		}

		let url = new URL(uri);
		url.searchParams.set("path", path);
		url.searchParams.set("type", type);
		url.searchParams.set("linkage", linkage);
		url = url.toString();

		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static fileboxSearchFiles = async (search_text) => {
		const uri = API.url + "/filebox/search.json";
		const tokenID = await Auth.GetTokenID();
		if (!tokenID) {
			return false;
		}

		let data = {
			search_text: search_text,
		};
		// console.log(data);

		return await new Promise((resolve, reject) => {
			axios.post(uri, data, {
				headers: {
					Authorization: tokenID,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static CreateFileBoxNewFolder = async (path) => {
		const uri = API.url + "/filebox/newFolder.json";
		const tokenID = await Auth.GetTokenID();
		if (!tokenID) {
			return false;
		}

		return await new Promise((resolve, reject) => {
			axios.post(uri, { path }, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static fileboxDownload = async (path) => {
		const uri = API.url + "/filebox/downloadRequest.json";
		const tokenID = await Auth.GetTokenID();
		if (!tokenID) {
			return false;
		}

		let url = new URL(uri);
		url.searchParams.set("path", path);
		url = url.toString();

		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static fileboxDeleteFile = async (path) => {
		const uri = API.url + "/filebox/deleteFile.json";
		const tokenID = await Auth.GetTokenID();
		if (!tokenID) {
			return false;
		}

		return await new Promise((resolve, reject) => {
			axios.post(uri, { path }, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static fileboxDeleteFolder = async (path) => {
		const uri = API.url + "/filebox/deleteFolder.json";
		const tokenID = await Auth.GetTokenID();
		if (!tokenID) {
			return false;
		}

		return await new Promise((resolve, reject) => {
			axios.post(uri, { path }, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static documentDownload = async (path) => {
		const uri = API.url + "/document/downloadRequest.json";
		const tokenID = await Auth.GetTokenID();
		if (!tokenID) {
			return false;
		}

		let url = new URL(uri);
		url.searchParams.set("path", path);
		url = url.toString();

		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static GetPageData = async (doc_type, function_type_id, is_recache = undefined) => {
		const uri = API.url + "/page/data";
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();
		if (!UserInfo || !tokenID) {
			return false;
		}
		let function_type;
		switch (doc_type) {
			case "evaluation":
				function_type = 4;
				break;
			case "verification":
				function_type = 4;
				break;
			case "signer":
				function_type = 1;
				break;
			case "circle":
				function_type = 3;
				break;
			case "sender":
				function_type = 2;
				break;
			default:
				function_type = function_type_id;
				break;
		}
		// let queryParams = new URLSearchParams();
		// queryParams.set("tenant_id", UserInfo.tenant_id);
		// queryParams.set("function_type", doc_typ/e);
		// let url = uri + "?" + queryParams.toString();
		// console.log(url);
		// console.log(UserInfo);

		let result = await axios.get(uri, {
			headers: {
				Authorization: tokenID,
			},
			params: {
				function_type: function_type,
				// is_recache: is_recache
			}
		});
		currencyOptionsState.set(result.data.payload.currencies);
		return result.data.payload;
	}

	static GetHostGuestViewerInitData = async (doc_type, page_number, page_size, search) => {
		const uri = API.url + "/document/data/init";
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();
		if (!UserInfo || !tokenID) {
			return false;
		}
		let function_type;
		switch (doc_type) {
			case "evaluation":
				function_type = 4;
				break;
			case "verification":
				function_type = 4;
				break;
			case "signer":
				function_type = 1;
				break;
			case "circle":
				function_type = 3;
				break;
			case "sender":
				function_type = 2;
				break;
		}

		let result = await axios.get(uri, {
			headers: {
				Authorization: tokenID,
			},
			params: {
				function_type: function_type,
				page_number,
				page_size,
				search,
			}
		});
		return result.data;
	}


	static GetHostList = async (doc_type, page_number, page_size, search) => {
		const uri = API.url + "/document/host/list";
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();
		if (!UserInfo || !tokenID) {
			return false;
		}
		let function_type;
		switch (doc_type) {
			case "evaluation":
				function_type = 4;
				break;
			case "verification":
				function_type = 4;
				break;
			case "signer":
				function_type = 1;
				break;
			case "circle":
				function_type = 3;
				break;
			case "sender":
				function_type = 2;
				break;
		}

		let result = await axios.get(uri, {
			headers: {
				Authorization: tokenID,
			},
			params: {
				function_type: function_type,
				page_number,
				page_size,
				search,
			}
		});
		return result.data;
	}

	static GetHostUser = async (doc_type, page_number, page_size, search) => {
		const uri = API.url + "/document/host/user-list";

		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();
		if (!UserInfo || !tokenID) {
			return false;
		}
		let function_type;
		switch (doc_type) {
			case "evaluation":
				function_type = 4;
				break;
			case "verification":
				function_type = 4;
				break;
			case "signer":
				function_type = 1;
				break;
			case "circle":
				function_type = 3;
				break;
			case "sender":
				function_type = 2;
				break;
		}

		let result = await axios.get(uri, {
			headers: {
				Authorization: tokenID,
			},
			params: {
				function_type: function_type,
				page_number,
				page_size,
				search,
			}
		});
		return result.data;
	}

	static GetHostGroup = async (doc_type, page_number, page_size, search) => {
		const uri = API.url + "/document/host/group-list";

		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();
		if (!UserInfo || !tokenID) {
			return false;
		}
		let function_type;
		switch (doc_type) {
			case "evaluation":
				function_type = 4;
				break;
			case "verification":
				function_type = 4;
				break;
			case "signer":
				function_type = 1;
				break;
			case "circle":
				function_type = 3;
				break;
			case "sender":
				function_type = 2;
				break;
		}

		let result = await axios.get(uri, {
			headers: {
				Authorization: tokenID,
			},
			params: {
				function_type: function_type,
				page_number,
				page_size,
				search,
			}
		});
		return result.data;
	}

	static GetHostGroupDetail = async (doc_type, group_id, page_number, page_size, search) => {
		const uri = API.url + "/document/host/group-detail";

		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();
		if (!UserInfo || !tokenID) {
			return false;
		}
		let function_type;
		switch (doc_type) {
			case "evaluation":
				function_type = 4;
				break;
			case "verification":
				function_type = 4;
				break;
			case "signer":
				function_type = 1;
				break;
			case "circle":
				function_type = 3;
				break;
			case "sender":
				function_type = 2;
				break;
		}

		let result = await axios.get(uri, {
			headers: {
				Authorization: tokenID,
			},
			params: {
				function_type: function_type,
				group_id: group_id,
				page_number,
				page_size,
				search
			}
		});
		return result.data;
	}

	static GetGuestList = async (page_number, page_size, search) => {
		const uri = API.url + "/document/guest/list";

		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();
		if (!UserInfo || !tokenID) {
			return false;
		}

		let result = await axios.get(uri, {
			headers: {
				Authorization: tokenID,
			},
			params: {
				page_number,
				page_size,
				search,
			}
		});
		return result.data;
	}

	static GetGuestGroupDetail = async (group_id, page_number, page_size, search) => {
		const uri = API.url + "/document/guest/group-detail";

		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();
		if (!UserInfo || !tokenID) {
			return false;
		}

		let result = await axios.get(uri, {
			headers: {
				Authorization: tokenID,
			},
			params: {
				group_id: group_id,
				page_number,
				page_size,
				search
			}
		});
		return result.data;
	}

	static GetViewerList = async (doc_type, page_number, page_size, search) => {
		const uri = API.url + "/document/viewer/list";

		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();
		if (!UserInfo || !tokenID) {
			return false;
		}
		let function_type;
		switch (doc_type) {
			case "evaluation":
				function_type = 4;
				break;
			case "verification":
				function_type = 4;
				break;
			case "signer":
				function_type = 1;
				break;
			case "circle":
				function_type = 3;
				break;
			case "sender":
				function_type = 2;
				break;
		}

		let result = await axios.get(uri, {
			headers: {
				Authorization: tokenID,
			},
			params: {
				function_type: function_type,
				page_number,
				page_size,
				search,
			}
		});
		return result.data;
	}

	static GetViewerGroup = async (doc_type, page_number, page_size, search) => {
		const uri = API.url + "/document/viewer/group-list";

		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();
		if (!UserInfo || !tokenID) {
			return false;
		}
		let function_type;
		switch (doc_type) {
			case "evaluation":
				function_type = 4;
				break;
			case "verification":
				function_type = 4;
				break;
			case "signer":
				function_type = 1;
				break;
			case "circle":
				function_type = 3;
				break;
			case "sender":
				function_type = 2;
				break;
		}

		let result = await axios.get(uri, {
			headers: {
				Authorization: tokenID,
			},
			params: {
				function_type: function_type,
				page_number,
				page_size,
				search,
			}
		});
		return result.data;
	}


	static GetViewerGroupDetail = async (doc_type, group_id, page_number, page_size, search) => {
		const uri = API.url + "/document/viewer/group-detail";

		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();
		if (!UserInfo || !tokenID) {
			return false;
		}
		let function_type;
		switch (doc_type) {
			case "evaluation":
				function_type = 4;
				break;
			case "verification":
				function_type = 4;
				break;
			case "signer":
				function_type = 1;
				break;
			case "circle":
				function_type = 3;
				break;
			case "sender":
				function_type = 2;
				break;
		}

		let result = await axios.get(uri, {
			headers: {
				Authorization: tokenID,
			},
			params: {
				function_type: function_type,
				group_id: group_id,
				page_number,
				page_size,
				search
			}
		});
		return result.data;
	}

	static ValidateWord2PdfData = async (doc_type, host_list, guest_list, viewer_list) => {
		const uri = API.url + "/document/word2pdf/validation";

		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();
		if (!UserInfo || !tokenID) {
			return false;
		}
		let function_type;
		switch (doc_type) {
			case "evaluation":
				function_type = 4;
				break;
			case "verification":
				function_type = 4;
				break;
			case "signer":
				function_type = 1;
				break;
			case "circle":
				function_type = 3;
				break;
			case "sender":
				function_type = 2;
				break;
		}

		let result = await axios.post(uri, {
			function_type: function_type,
			host_list: host_list,
			guest_list: guest_list,
			viewer_list: viewer_list,
		}, {
			headers: {
				Authorization: tokenID,
			},
		});
		return result.data;
	}

	static GetUserList = async (groupby = null, forceGroup = [], status = 0) => {
		const uri = API.url + "/user/list.json";
		const tokenID = await Auth.GetTokenID();

		if (!tokenID) {
			return false;
		}

		let url = new URL(uri);
		url.searchParams.set("status", status);

		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				},
			}).then(resp => {
				if (groupby) {
					if (resp.data.length === 0) {
						resolve({});
					} else {
						let firstRecord = resp.data[0];
						if (Object.keys(firstRecord).includes(groupby)) {
							let result = {};
							for (let g of forceGroup) {
								result[g] = {};
							}
							for (let record of resp.data) {
								let groupKey = record[groupby];
								if (!result.hasOwnProperty(groupKey)) {
									result[groupKey] = {};
								}
								result[groupKey][record.user_id] = record;
							}
							resolve(result);
						} else {
							resolve(resp.data);
						}
					}
				} else {
					resolve(resp.data);
				}
			}).catch(error => {
				reject(error);
			});
		});
	}

	static CreateDocument = async (data) => {
		const uri = API.url + "/document/create.json";
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();
		if (!UserInfo || !tokenID) {
			return false;
		}
		// console.log(data);
		// return;
		return await new Promise((resolve, reject) => {
			axios.post(uri, data, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static UpdateDocumentViewers = async (data) => {
		const uri = API.url + "/document/update/viewers.json";
		const tokenID = await Auth.GetTokenID();
		if (!tokenID) {
			return false;
		}
		// console.log(data);
		// return;
		return await new Promise((resolve, reject) => {
			axios.post(uri, data, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static SaveDocument = async (data) => {
		// const uri = API.url + "/document/save.json";
		const uri = `${API.domain_new}/api/document-save.json`;
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();
		if (!UserInfo || !tokenID) {
			return false;
		}
		// console.log(data);
		// return;
		return await new Promise((resolve, reject) => {
			axios.post(uri, data, {
				headers: {
					"Accept": "application/json",
					"Content-type": "application/json",
					"Authorization": tokenID,
				}
			}).then(async resp => {
				// Support SQS
				if (resp.data.hasOwnProperty('Error')) {
					reject({
						"response": {
							"data": {
								"error": resp.data.Error.Code,
								"message": resp.data.Error.Message,
								"sqsInfo": resp.data,
							},
						},
					});
				} else {
					resolve(resp.data);
				}
			}).catch(error => {
				reject(error);
			});
		});
	}

	static HostRevisionReturnDocument = async (data) => {
		const uri = `${API.url}/document-revision/host/return`;
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();
		if (!UserInfo || !tokenID) {
			return false;
		}
		// console.log(data);
		// return;
		return await new Promise((resolve, reject) => {
			axios.post(uri, data, {
				headers: {
					"Accept": "application/json",
					"Content-type": "application/json",
					"Authorization": tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static GuestRevisionReturnDocument = async (token,data) => {
		const uri = `${API.url}/document-revision/guest/return`;
		return await new Promise((resolve, reject) => {
			axios.post(uri, data, {
				headers: {
					Authorization: token,
					"Content-Type": 'application/json'
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static RevisionCancelDocument = async (data) => {
		const uri = `${API.url}/document-revision/cancel`;
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();
		if (!UserInfo || !tokenID) {
			return false;
		}
		// console.log(data);
		// return;
		return await new Promise((resolve, reject) => {
			axios.post(uri, data, {
				headers: {
					"Accept": "application/json",
					"Content-type": "application/json",
					"Authorization": tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static ViewDocument = async (token, data) => {
		const uri = `${API.url}/document/guest/view`;

		return await new Promise((resolve, reject) => {
			axios.post(uri, data, {
				headers: {
					Authorization: token,
					"Content-Type": 'application/json'
				},
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static ViewDocumentGuestCollab = async (data) => {
		const uri = `${API.url}/document/view`;
		const tokenID = await Auth.GetTokenID();

		return await new Promise((resolve, reject) => {
			axios.post(uri, data, {
				headers: {
					Authorization: tokenID,
					"Content-Type": 'application/json'
				},
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static convertImageToPdf = async (formData) => {
		const uri = `${API.domain_new}/api/convert-image.json`;

		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();
		if (!UserInfo || !tokenID) {
			return false;
		}

		return await new Promise((resolve, reject) => {
			axios.post(uri, formData, {
				headers: {
					"Authorization": tokenID,
					'Content-Type': 'multipart/form-data'
				},
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static convertFileToPdf = async (formData, uploadProgessCb = () => {}) => {
		const uri = `${API.domain_new}/api/convert-to-pdf.json`;

		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();
		if (!UserInfo || !tokenID) {
			return false;
		}

		return await new Promise((resolve, reject) => {
			axios.post(uri, formData, {
				headers: {
					"Authorization": tokenID,
					'Content-Type': 'multipart/form-data'
				},
				onUploadProgress: uploadProgessCb,
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static embedFileToPdf = async (data, progessCb = () => {}) => {
		const uri = `${API.domain_new}/api/embed-file.json`;

		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();
		if (!UserInfo || !tokenID) {
			return false;
		}

		return await new Promise((resolve, reject) => {
			axios.post(uri, data, {
				headers: {
					"Authorization": tokenID,
				},
				onUploadProgress: progessCb,
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static getDocumentBlob = async (uri, downoadProgessCb = () => {}) => {
		return await new Promise((resolve, reject) => {
			axios.get(uri, {
				responseType: 'blob',
				onDownloadProgress: downoadProgessCb,
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static extractPDFText = async (formData) => {
		const uri = `${API.domain_new}/api/extract-pdf.json`;
		// const uri = API.url + "/api/bulk-verification.json";
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();
		if (!UserInfo || !tokenID) {
			return false;
		}
		// console.log(formData)
		// console.log(data);
		// return;
		return await new Promise((resolve, reject) => {
			axios.post(uri, formData, {
				headers: {
					"Authorization": tokenID,
					'Content-Type': 'multipart/form-data'
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static extractFileText = async (formData, pageCount, fileHash, functionType) => {
		const uri = `${API.domain_new}/api/generate-presign-google.json`;
		// const uri = "http://127.0.0.1:5000/api/generate-presign-google.json";
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();
		if (!UserInfo || !tokenID) {
			return false;
		}

		return await new Promise((resolve, reject) => {
			axios.post(uri, {}, {
				headers: {
					"Authorization": tokenID,
				}
			}).then(async resp => {
				const payload = resp.data.payload;
				const presignUrl = payload.presign_url;
				const fileId = payload.file_id;

				if (!presignUrl || !fileId) {
					reject({
						"response": {
							"data": {
								"error": "presign_url or file_id is empty",
								"message": "presign_url or file_id is empty",
							},
						},
					});
				}

				await axios.put(presignUrl, formData).then(async (resp) => {
					const extractUri = `${API.domain_new}/api/extract-file.json`;
					// const extractUri = "http://127.0.0.1:5000/api/extract-file.json";
					await axios.post(extractUri, {
						"file_id": fileId,
						"page_count": pageCount,
						"file_hash": fileHash,
						"function_type": functionType
					}, {
						headers: {
							"Authorization": tokenID,
							"Accept": "application/json",
							"Content-type": "application/json",
						}
					}).then(async resp => {
						resolve(resp.data);
					}).catch(error => {
						reject(error);
					});
				}).catch(error => {
					console.log({error});
					reject(error);
				});
			}).catch(error => {
				reject(error);
			});
		});
	}

	static BulkSaveDocument = async (data) => {
		const uri = `${API.domain_new}/api/bulk-verification.json`;
		// const uri = API.url + "/api/bulk-verification.json";
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();
		if (!UserInfo || !tokenID) {
			return false;
		}
		// console.log(data);
		// return;
		return await new Promise((resolve, reject) => {
			axios.post(uri, {
				"doc_info_list": data,
			}, {
				headers: {
					"Accept": "application/json",
					"Content-type": "application/json",
					"Authorization": tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static BatchVerification = async (data) => {
		const uri = `${API.domain_new}/api/batch-verification.json`;
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();
		if (!UserInfo || !tokenID) {
			return false;
		}
		return await new Promise((resolve, reject) => {
			axios.post(uri, {
				"doc_info_list": data,
			}, {
				headers: {
					"Accept": "application/json",
					"Content-type": "application/json",
					"Authorization": tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static BatchSender = async (data) => {
		const uri = `${API.domain_new}/api/batch-sender.json`;
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();
		if (!UserInfo || !tokenID) {
			return false;
		}
		return await new Promise((resolve, reject) => {
			axios.post(uri, {
				"doc_info_list": data,
			}, {
				headers: {
					"Accept": "application/json",
					"Content-type": "application/json",
					"Authorization": tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static Word2PDF = async (data) => {
		const uri = API.url + "/document/word2pdf.json";
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();
		if (!UserInfo || !tokenID) {
			return false;
		}
		// console.log(data);
		// return;
		return await new Promise((resolve, reject) => {
			axios.post(uri, data, {
				headers: {
					"Accept": "application/json",
					"Content-type": "application/json",
					"Authorization": tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static Word2PDFBulkSave = async (data) => {
		const uri = API.url + "/document/word2pdf-save.json";
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();
		if (!UserInfo || !tokenID) {
			return false;
		}
		// console.log(data);
		// return;
		return await new Promise((resolve, reject) => {
			axios.post(uri, data, {
				headers: {
					"Accept": "application/json",
					"Content-type": "application/json",
					"Authorization": tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static DeleteDocument = async (document_id) => {
		const uri = API.url + "/document/delete.json";
		// const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();
		if (!tokenID) {
			return false;
		}
		// return;
		return await new Promise((resolve, reject) => {
			axios.post(uri, { document_id: document_id }, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static FileUpload = async (file, path = "", file_info = "", file_tail = "", onProgressChange) => {
		console.log({file});
		const uri = API.url + "/upload.json";
		const tokenID = await Auth.GetTokenID();
		if (!tokenID) {
			return false;
		}

		let file_name = "";
		let file_size = 0;

		if (file_info !== "") {
			file_name = file_info.name;
			file_size = file_info.size;
		}

		return await new Promise(async (resolve, reject) => {
			axios.post(uri, { path, file_name, file_size, file_tail }, {
				headers: {
					Authorization: tokenID,
				}
			}).then(resp => {
				let tempInfo = resp.data;
				let tempUrl = tempInfo.url;
				let fields = tempInfo.fields;
				let formData = new FormData();
				for (let i in fields) {
					formData.set(i, fields[i]);
				}
				formData.set("file", file);
				axios.post(tempUrl, formData, {
					onUploadProgress: function (progressEvent) {
						const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
						console.log('Upload progress: ' + progress + '%');
						onProgressChange(progress)
					}
				}).then(resp => {
					resolve(tempInfo);
				}).catch(error => {
					reject(error);
				});
			}).catch(error => {
				reject(error);
			});
			// console.log(uploadInfo);
		});

	}

	static signGuestDocument = async (token) => {
		const uri = `${API.domain_new}/api/guest/document-sign.json`;

		return await new Promise((resolve, reject) => {
			axios.post(uri, null, {
				headers: {
					Authorization: token,
					"Content-Type": 'application/json'
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static DocumentForwardSign = async (user) => {
		const uri = API.url + "/document/forward.json";
		const tokenID = await Auth.GetTokenID();

		// console.log(data);

		return await new Promise((resolve, reject) => {
			axios.post(uri, user, {
				headers: {
					Authorization: tokenID,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static CollabForwardSign = async (user) => {
		const uri = API.url + '/document/forward/collaboration';
		const tokenID = await Auth.GetTokenID();

		// console.log(data);

		return await new Promise((resolve, reject) => {
			axios.post(uri, user, {
				headers: {
					Authorization: tokenID,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static signDocument = async (document_id) => {
		// const uri = API.url + "/document/sign.json";
		const uri = `${API.domain_new}/api/host/document-sign.json`;
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		let data = {
			document_id: document_id,
			user_id: parseInt(UserInfo.user_id),
		};
		// console.log(data);

		return await new Promise((resolve, reject) => {
			axios.post(uri, data, {
				headers: {
					Authorization: tokenID,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static saveDocumentlog = async (document_id) => {
		// const uri = API.url + "/document/sign.json";
		const uri = API.url + "/document/signProtectPDF.json";
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		let data = {
			document_id: document_id,
			user_id: parseInt(UserInfo.user_id),
		};
		// console.log(data);

		return await new Promise((resolve, reject) => {
			axios.post(uri, data, {
				headers: {
					"Accept": "application/json",
					"Content-type": "application/json",
					"Authorization": tokenID,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static verifyDocument = async (document_id) => {
		// const uri = API.url + "/document/verify.json";
		const uri = `${API.domain_new}/api/document-timestamp.json`;
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		let data = {
			document_id: document_id,
			user_id: parseInt(UserInfo.user_id),
		};
		// console.log(data);

		return await new Promise((resolve, reject) => {
			axios.post(uri, data, {
				headers: {
					Authorization: tokenID,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static getGuestDocumentFile = async (token, file_name) => {
		const uri = `${API.media}/document/guest/${file_name}`;

		return await new Promise((resolve, reject) => {
			axios.get(uri, {
				headers: {
					Authorization: token,
					Accept: "application/pdf",
				},
				responseType: 'blob',
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	static getDocumentFile = async (document_id, file_name) => {
		const uri = `${API.media}/document/${document_id}/${file_name}`;
		const tokenID = await Auth.GetTokenID();

		return await new Promise((resolve, reject) => {
			axios.get(uri, {
				headers: {
					Authorization: tokenID,
					Accept: "application/pdf",
				},
				responseType: 'blob',
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	static ConvertDoc2PDF = async (data, is_ignore_keys = false) => {
		const uri = `${API.media}/document/word2pdf.json`;
		const tokenID = await Auth.GetTokenID();

		if (!tokenID) {
			return false;
		}
		data.is_ignore_keys = is_ignore_keys
		// console.log(data);
		// return;
		return await new Promise((resolve, reject) => {
			axios.post(uri, data, {
				headers: {
					"Accept": "application/json",
					"Content-type": "application/json",
					"Authorization": tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static getGuestDocumentInfo = async (token, otpCode, countIncorrect) => {
		const uri = API.url + "/guest/document/info.json";

		return await new Promise((resolve, reject) => {
			axios.get(uri, {
				headers: {
					Authorization: token,
				},
				params: {
					otpCode,
					countIncorrect
				}
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	static getDocumentInfo = async (document_id) => {
		const uri = API.url + "/document/info.json";
		const tokenID = await Auth.GetTokenID();

		let queryParams = new URLSearchParams();
		queryParams.set("document_id", document_id);
		let url = uri + "?" + queryParams.toString();

		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}
	static UpdateLanguage = async (language) => {

		const uri = API.url + "/user/language.json";
		const tokenID = await Auth.GetTokenID();

		if (!tokenID) {
			return false;
		}
		console.log(language);
		// return;
		return await new Promise((resolve, reject) => {
			axios.post(uri, { language: language }, {
				headers: {
					"Accept": "application/json",
					"Content-type": "application/json",
					"Authorization": tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});



	}

	static DocumentInfoSave = async (data) => {
		// const uri = API.url + "/document/info.json";
		const uri = API.url + "/document/new-update.json";
		const tokenID = await Auth.GetTokenID();
		return await new Promise((resolve, reject) => {
			axios.patch(uri, data, {
				headers: {
					"Accept": "application/json",
					"Content-type": "application/json",
					"Authorization": tokenID,
				}
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	static guestDownloadAllAsZip = async (token, attachmentOnly = false) => {
		const uri = API.domain_new + "/api/v2/guest/document/download.json";
		// console.log(token);
		let queryParams = new URLSearchParams();
		queryParams.set("attachment_only", attachmentOnly);
		let url = uri + "?" + queryParams.toString();
		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: token,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	static downloadAllAsZip = async (document_id, attachmentOnly = false) => {
		const uri = API.domain_new + "/api/v2/document/download.json";
		const tokenID = await Auth.GetTokenID();

		let queryParams = new URLSearchParams();
		queryParams.set("document_id", document_id);
		queryParams.set("attachment_only", attachmentOnly);
		let url = uri + "?" + queryParams.toString();

		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	static downloadForPrinting = async (document_id) => {
		const uri = API.domain_new + "/api/v2/document/download-for-printing.json";
		const tokenID = await Auth.GetTokenID();

		let queryParams = new URLSearchParams();
		queryParams.set("document_id", document_id);
		let url = uri + "?" + queryParams.toString();

		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static downloadForPrintingGuest = async (token) => {
		const uri = API.url + "/document/guest/download-for-printing.json";

		return await new Promise((resolve, reject) => {
			axios.get(uri, {
				headers: {
					Authorization: token,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}


	static previewForPrinting = async (document_id) => {
		const uri = API.domain_new + "/api/v2/document/preview-for-printing.json";
		const tokenID = await Auth.GetTokenID();

		let queryParams = new URLSearchParams();
		queryParams.set("document_id", document_id);
		let url = uri + "?" + queryParams.toString();

		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static previewForPrintingGuest = async (token) => {
		const uri = API.url + "/document/guest/preview-for-printing.json";

		return await new Promise((resolve, reject) => {
			axios.get(uri, {
				headers: {
					Authorization: token,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static getDashboardData = async () => {
		const uri = API.url + "/dashboard.json";
		const tokenID = await Auth.GetTokenID();

		return await new Promise((resolve, reject) => {
			axios.get(uri, {
				headers: {
					Authorization: tokenID,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	static getDocumentStatusList = async (data) => {
		const uri = API.url + "/master/document/status.json";
		const tokenID = await Auth.GetTokenID();

		let url = new URL(uri);
		url.searchParams.set("function_type", data.function_type);
		for (let i in data.function_type) {
			url.searchParams.set(i, data[i]);
			console.log(i, data[i]);
		}
		url = url.toString();

		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	static getDocumentList = async (data) => {
		const uri = API.url + "/document/list.json";
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		let url = new URL(uri);
		url.searchParams.set("tenant_id", UserInfo.tenant_id);
		// url.searchParams.set("function_type", data.function_type);
		// url.searchParams.set("status_id", data.status_id);
		// url.searchParams.set("sorting_code", data.sorting_code);
		// url.searchParams.set("skip", data.skip);
		// url.searchParams.set("take", data.take);
		for (let i in data) {
			let value = data[i];
			if (value instanceof Object) {
				value = JSON.stringify(value);
			}
			url.searchParams.set(i, value);
		}

		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static getEvaluationSearchList = async (data) => {
		const uri = API.url + "/evaluation/search/list.json";
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		let url = new URL(uri);
		url.searchParams.set("tenant_id", UserInfo.tenant_id);
		for (let i in data) {
			let value = data[i];
			if (value instanceof Object) {
				value = JSON.stringify(value);
			}
			url.searchParams.set(i, value);
		}

		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static getDocumentFilterList = async (data) => {
		const uri = API.url + "/document/filter";
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		let url = new URL(uri);
		url.searchParams.set("tenant_id", UserInfo.tenant_id);
		for (let i in data) {
			let value = data[i];
			if (value instanceof Object) {
				value = JSON.stringify(value);
			}
			url.searchParams.set(i, value);
		}

		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static getDocumentListDownload = async (data) => {
		const uri = `${API.domain_new}/api/v2/document/listDownload.json`;
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		let url = new URL(uri);
		url.searchParams.set("tenant_id", UserInfo.tenant_id);

		for (let i in data) {
			url.searchParams.set(i, data[i]);
		}
		url = url.toString();

		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static getEvaluateHistory = async (data) => {
		const uri = API.url + "/evaluation/list.json";
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		let url = new URL(uri);
		url.searchParams.set("tenant_id", UserInfo.tenant_id);
		for (let i in data) {
			url.searchParams.set(i, data[i]);
		}
		url = url.toString();

		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	static getEvaluationDetail = async (data) => {
		const uri = API.url + "/evaluation/detail";
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		let url = new URL(uri);
		url.searchParams.set("tenant_id", UserInfo.tenant_id);
		for (let i in data) {
			url.searchParams.set(i, data[i]);
		}
		url = url.toString();

		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	static getEvaluateSearchDocumentTitle = async (data) => {
		const uri = API.url + '/evaluation/search-suggestion.json';
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		let url = new URL(uri);
		url.searchParams.set("tenant_id", UserInfo.tenant_id);
		for (let i in data) {
			url.searchParams.set(i, data[i]);
		}
		url = url.toString();

		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	static startEvaluate = async (data) => {
		// const uri = API.url + "/evaluation/save.json";
		const uri = `${API.domain_new}` + "/api/batch-validation.json";

		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		data["tenant_id"] = UserInfo.tenant_id;

		return await new Promise((resolve, reject) => {
			axios.post(uri, data, {
				headers: {
					Authorization: tokenID,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	static startEvaluateRandom = async (data) => {
		// const uri = API.url + "/evaluation/random.json";
		const uri = `${API.domain_new}` + "/api/batch-validation.json";
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		return await new Promise((resolve, reject) => {
			axios.post(uri, data, {
				headers: {
					Authorization: tokenID,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	static verificationBulkRegister = async (data) => {
		// const uri = API.url + "/verification/bulkRegister.json";
		const uri = `${API.domain_new}/api/batch-timestamp.json`;
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		data["tenant_id"] = UserInfo.tenant_id;

		return await new Promise((resolve, reject) => {
			axios.post(uri, data, {
				headers: {
					Authorization: tokenID,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	/**  */
	static getCertificatesList = async () => {

		/* */
		const tokenID = await Auth.GetTokenID();

		/* */
		let url = new URL(API.url + "/certificates/list.json");

		/* */
		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	/** */
	static guestUpdateCertificate = async (token, data) => {
		/* */
		const url = new URL(API.url + "/guest/certificates/upload.json");

		/* */
		return await new Promise((resolve, reject) => {
			axios.post(url, data, {
				headers: {
					Authorization: token,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	/** */
	static updateCertificate = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/certificates/upload.json");

		/* */
		return await new Promise((resolve, reject) => {
			axios.post(url, data, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	/** */
	static deleteCertificate = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/certificates/delete.json");

		/* */
		return await new Promise((resolve, reject) => {
			axios.post(url, data, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	/** */
	static downloadCertificate = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/certificates/download.json");

		/* */
		return await new Promise((resolve, reject) => {
			axios.post(url, data, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	static toggleCertificateHSM = async (data) => {
		const uri = `${API.domain_new}/api/certificates/hsm.json`;
		const tokenID = await Auth.GetTokenID();
		const UserInfo = await Service.GetUserInfo();

		if (!UserInfo || !tokenID) {
			return false;
		}


		return await new Promise((resolve, reject) => {
			axios.post(uri, data, {
				headers: {
					Authorization: tokenID,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	/** Create HostUser (NOTE: insert only) */
	static setUserHost = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/user/host/save.json");

		/* */
		return await new Promise((resolve, reject) => {
			axios.post(url, data, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	/** Create HostUser (NOTE: update only [status, organizations]) */
	static updateUserHost = async (data) => {


		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/user/host/update.json");

		/* */
		return await new Promise((resolve, reject) => {
			axios.post(url, data, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	/** */
	static getUserHostList = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/user/host/list.json");

		/* */
		url.searchParams.set('tenant_id', UserInfo['tenant_id']);
		url.searchParams.set('status', data['status']);
		url.searchParams.set('search', data['search']);
		url.searchParams.set('skip', data['skip']);
		url.searchParams.set('take', data['take']);

		/* */
		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	static DeleteInactiveUser = async (data) => {
		/* */
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/user/host/delete.json");

		/* */
		url.searchParams.set('user_id', data['user_id']);

		/* */
		return await new Promise((resolve, reject) => {
			axios.delete(url, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	/** */
	static UserHostResendMail = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/user/host/resendMail.json");

		/* */
		return await new Promise((resolve, reject) => {
			axios.post(url, data, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	/** */
	static UploadCsvHostUser = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/user/host/upload.json");

		/* */
		return await new Promise((resolve, reject) => {
			axios.post(url, data, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	static getMultiPresignedUrl = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/csv/multi-presigned-url");

		/* */
		return await new Promise((resolve, reject) => {
			axios.post(url, data, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static userProcessCsvUpload = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/user/host/csv/process");

		/* */
		return await new Promise((resolve, reject) => {
			axios.post(url, data, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static DownloadCsvHostListUser = async (data) => {
		// const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();
		const userInfo = await Service.GetUserInfo();

		let url = new URL(API.url + "/user/host/csv/export");

		/* */
		url.searchParams.set('tenant_id', userInfo['tenant_id']);
		url.searchParams.set('status', data['status']);
		url.searchParams.set('search', data['search']);


		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static DownloadCsvListGuest = async (data) => {
		// const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();
		const userInfo = await Service.GetUserInfo();

		let url = new URL(API.url + "/guest/csv/export");

		/* */
		url.searchParams.set('tenant_id', userInfo['tenant_id']);
		url.searchParams.set('status', data['status']);
		url.searchParams.set('search', data['search']);
		url.searchParams.set('create_user', data['create_user']);


		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	/** */
	static UserHostSendMail = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/user/host/sendMail.json");

		/* */
		return await new Promise((resolve, reject) => {
			axios.post(url, data, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	static resendEmail = async (document_id, user_id, is_revision = false) => {
		// const uri = API.url + "/document/resendEmail.json";
		const uri = API.url + "/document/resend-email";
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		let data = {
			document_id: document_id,
			user_id: parseInt(user_id),
			send_user_id: UserInfo.user_id,
			is_revision: is_revision
		};
		// console.log(data);

		return await new Promise((resolve, reject) => {
			axios.post(uri, data, {
				headers: {
					Authorization: tokenID,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static EmailControllerSave = async (payload) => {
		const uri = API.url + "/email/controller.json";
		const tokenID = await Auth.GetTokenID();

		return await new Promise((resolve, reject) => {
			axios.post(uri, {
				"email_control": payload,
			}, {
				headers: {
					Authorization: tokenID,
				},
			}).then(async resp => {
				await GetUserInfoNew();
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	/* Get Approver List */
	static getGuestApproverList = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* Set default value */
		if (data['tenant_id'] == null) {
			data['tenant_id'] = UserInfo['tenant_id'];
		}

		/* Prepare URL */
		const url = new URL(API.url + "/guest/approver/list.json");

		/* Convert RequestBody to URL-QueryString */
		url.searchParams.set('tenant_id', data['tenant_id']);
		url.searchParams.set('page_size', data['page_size']);
		url.searchParams.set('page_number', data['page_number']);
		data['search'].length > 0 && url.searchParams.set('search', data['search']);

		/* Wating for HTTP-Response before return */
		return await new Promise((resolve, reject) => {

			/* Send HTTP-Request */
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});

		});

	}

	/* Select List of GuestUser's */
	static getUserGuestList = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* Set default value */
		if (data['tenant_id'] == null) {
			data['tenant_id'] = UserInfo['tenant_id'];
		}

		/* Prepare URL */
		const url = new URL(API.url + "/guest/list.json");

		/* Convert RequestBody to URL-QueryString */
		url.searchParams.set('tenant_id', data['tenant_id']);
		data['create_user'] && url.searchParams.set('create_user', data['create_user']);
		url.searchParams.set('status', data['status']);
		url.searchParams.set('search', data['search']);
		url.searchParams.set('page_size', data['page_size']);
		url.searchParams.set('page_number', data['page_number']);

		/* Wating for HTTP-Response before return */
		return await new Promise((resolve, reject) => {

			/* Send HTTP-Request */
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});

		});

	}

	/* Insert/Update GuestUser */
	static setUserGuest = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		if (data["tenant_id"] == null) {
			data["tenant_id"] = UserInfo["tenant_id"];
		}

		/* */
		if (data.user_id) {
			const url = new URL(API.url + "/guest/update.json");

			return await new Promise((resolve, reject) => {
				axios.patch(url, data, {
					headers: {
						Authorization: tokenID,
					},
				}).then(async resp => {
					resolve(resp.data);
				}).catch(error => {
					reject(error);
				});
			});
		} else {
			const url = new URL(API.url + "/guest/create.json");

			return await new Promise((resolve, reject) => {
				axios.post(url, data, {
					headers: {
						Authorization: tokenID,
					},
				}).then(async resp => {
					resolve(resp.data);
				}).catch(error => {
					reject(error);
				});
			});
		}

	}

	/** */
	static UploadCsvGuestUser = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/guest/csvUpload.json");

		/* */
		return await new Promise((resolve, reject) => {
			axios.post(url, data, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	static guestPreProcessCsvUpload = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/guest/csv/pre-process");

		/* */
		return await new Promise((resolve, reject) => {
			axios.post(url, data, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static guestProcessCsvUpload = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/guest/csv/process");

		/* */
		return await new Promise((resolve, reject) => {
			axios.post(url, data, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	/** */
	static userGenerateApiToken = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/user/generate-api-token-key.json");

		/* Waiting HTTP-Response before return */
		return await new Promise((resolve, reject) => {

			/* Send HTTP-Request */
			axios.post(url, data, {
				headers: {
					Authorization: tokenID,
				},
			}).then(async resp => {
				UserInfo["api_token_key"] = resp.data["token"];
				this.SaveUserInfoToLocalStorage(UserInfo);
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});

		});

	}

	/** */
	static userChangePassword = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/user/password-change");

		/* Waiting HTTP-Response before return */
		return await new Promise((resolve, reject) => {

			/* Send HTTP-Request */
			axios.patch(url, data, {
				headers: {
					Authorization: tokenID,
				},
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});

		});

	}

	/** */
	static userChangeProfileImage = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/user/profile-image-change.json ");

		/* Waiting HTTP-Response before return */
		return await new Promise((resolve, reject) => {

			/* Send HTTP-Request */
			axios.post(url, data, {
				headers: {
					Authorization: tokenID,
				},
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});

		});

	}

	static GetSearchData = async () => {
		const uri = API.url + "/search/form";
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();
		if (!UserInfo || !tokenID) {
			return false;
		}

		let result = await axios.get(uri, {
			headers: {
				Authorization: tokenID,
			},
		});
		return result.data;
	}

	static getSearchDocumentList = async (data) => {
		const uri = API.url + "/search/data";

		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		let url = new URL(uri);
		url.searchParams.set("tenant_id", UserInfo.tenant_id);
		url.searchParams.set("sorting_code", data.sorting_code);
		url.searchParams.set("active_flg", data.active_flg);
		url.searchParams.set("page_number", data.page_number);
		url.searchParams.set("page_size", data.page_size);
		url.searchParams.set("function_type", data.function_type);
		url.searchParams.set("status", data.status);
		url.searchParams.set("document_type", data.document_type);
		url.searchParams.set("title", data.title);
		url.searchParams.set("preservation_require", data.preservation_require);
		url.searchParams.set("amount_from", data.amount_from.replaceAll(",", ""));
		url.searchParams.set("amount_to", data.amount_to.replaceAll(",", ""));
		url.searchParams.set("currency", data.currency);
		url.searchParams.set("contract_date_start", data.contract_date_from);
		url.searchParams.set("contract_date_end", data.contract_date_to);
		url.searchParams.set("expiry_date_start", data.expire_date_from);
		url.searchParams.set("expiry_date_end", data.expire_date_to);
		url.searchParams.set("effective_date_from", data.effective_date_from);
		url.searchParams.set("effective_date_to", data.effective_date_to);
		url.searchParams.set("circle_finish_date_from", data.circle_finish_date_from);
		url.searchParams.set("circle_finish_date_to", data.circle_finish_date_to);
		url.searchParams.set("product_name", data.product_name);
		url.searchParams.set("reference_document", data.reference_document);
		url.searchParams.set("counter_party_name", data.counter_party_name);
		url.searchParams.set("file_name", data.file_name);
		url.searchParams.set("meta_attributes", JSON.stringify(data.meta_attributes));
		url.searchParams.set("create_datetime_start", data.create_date_from);
		url.searchParams.set("create_datetime_end", data.create_date_to);
		url.searchParams.set("deal_datetime_start", data.deal_date_from);
		url.searchParams.set("deal_datetime_end", data.deal_date_to);
		url.searchParams.set("dateofnoti", data.dateofnoti);
		url.searchParams.set("select_datenoti1", data.select_datenoti1);
		url.searchParams.set("document_id", data.document_id);
		url.searchParams.set("complete_signing_datetime_from", data.complete_signing_datetime_from);
		url.searchParams.set("complete_signing_datetime_to", data.complete_signing_datetime_to);
		url = url.toString();

		// console.log("data", data);
		// console.log(url);

		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	static getSearchDocumentField = async (data) => {
		const uri = API.url + "/search/field";

		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		let url = new URL(uri);
		url.searchParams.set("tenant_id", UserInfo.tenant_id);
		url.searchParams.set("sorting_code", data.sorting_code);
		url.searchParams.set("active_flg", data.active_flg);
		url.searchParams.set("page_number", data.page_number);
		url.searchParams.set("page_size", data.page_size);
		url.searchParams.set("function_type", data.function_type);
		url.searchParams.set("status", data.status);
		url.searchParams.set("document_type", data.document_type);
		url.searchParams.set("title", data.title);
		url.searchParams.set("preservation_require", data.preservation_require);
		url.searchParams.set("amount_from", data.amount_from.replaceAll(",", ""));
		url.searchParams.set("amount_to", data.amount_to.replaceAll(",", ""));
		url.searchParams.set("currency", data.currency);
		url.searchParams.set("contract_date_start", data.contract_date_from);
		url.searchParams.set("contract_date_end", data.contract_date_to);
		url.searchParams.set("expiry_date_start", data.expire_date_from);
		url.searchParams.set("expiry_date_end", data.expire_date_to);
		url.searchParams.set("effective_date_from", data.effective_date_from);
		url.searchParams.set("effective_date_to", data.effective_date_to);
		url.searchParams.set("circle_finish_date_from", data.circle_finish_date_from);
		url.searchParams.set("circle_finish_date_to", data.circle_finish_date_to);
		url.searchParams.set("product_name", data.product_name);
		url.searchParams.set("reference_document", data.reference_document);
		url.searchParams.set("counter_party_name", data.counter_party_name);
		url.searchParams.set("file_name", data.file_name);
		url.searchParams.set("meta_attributes", JSON.stringify(data.meta_attributes));
		url.searchParams.set("create_datetime_start", data.create_date_from);
		url.searchParams.set("create_datetime_end", data.create_date_to);
		url.searchParams.set("deal_datetime_start", data.deal_date_from);
		url.searchParams.set("deal_datetime_end", data.deal_date_to);
		url.searchParams.set("dateofnoti", data.dateofnoti);
		url.searchParams.set("select_datenoti1", data.select_datenoti1);
		url.searchParams.set("document_id", data.document_id);
		url.searchParams.set("search_field", data.search_field);
		url = url.toString();

		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	static exportSearchDocument = async (data) => {
		const uri = API.url + "/search/export";

		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		let url = new URL(uri);
		url.searchParams.set("tenant_id", UserInfo.tenant_id);
		url.searchParams.set("sorting_code", data.sorting_code);
		url.searchParams.set("active_flg", data.active_flg);
		url.searchParams.set("function_type", data.function_type);
		url.searchParams.set("status", data.status);
		url.searchParams.set("document_type", data.document_type);
		url.searchParams.set("title", data.title);
		url.searchParams.set("preservation_require", data.preservation_require);
		url.searchParams.set("amount_from", data.amount_from.replaceAll(",", ""));
		url.searchParams.set("amount_to", data.amount_to.replaceAll(",", ""));
		url.searchParams.set("currency", data.currency);
		url.searchParams.set("contract_date_start", data.contract_date_from);
		url.searchParams.set("contract_date_end", data.contract_date_to);
		url.searchParams.set("expiry_date_start", data.expire_date_from);
		url.searchParams.set("expiry_date_end", data.expire_date_to);
		url.searchParams.set("effective_date_from", data.effective_date_from);
		url.searchParams.set("effective_date_to", data.effective_date_to);
		url.searchParams.set("circle_finish_date_from", data.circle_finish_date_from);
		url.searchParams.set("circle_finish_date_to", data.circle_finish_date_to);
		url.searchParams.set("product_name", data.product_name);
		url.searchParams.set("reference_document", data.reference_document);
		url.searchParams.set("counter_party_name", data.counter_party_name);
		url.searchParams.set("file_name", data.file_name);
		url.searchParams.set("meta_attributes", JSON.stringify(data.meta_attributes));
		url.searchParams.set("create_datetime_start", data.create_date_from);
		url.searchParams.set("create_datetime_end", data.create_date_to);
		url.searchParams.set("deal_datetime_start", data.deal_date_from);
		url.searchParams.set("deal_datetime_end", data.deal_date_to);
		url.searchParams.set("dateofnoti", data.dateofnoti);
		url.searchParams.set("select_datenoti1", data.select_datenoti1);
		url.searchParams.set("document_id", data.document_id);
		url = url.toString();

		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	/** */
	static getAppCertList = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/appcert/list.json");

		/* */
		url.searchParams.set('search', data['search']);
		url.searchParams.set('skip', data['skip']);
		url.searchParams.set('take', data['take']);

		/* */
		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	/** */
	static getAppCertUserList = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/appcert/user.json");

		/* */
		url.searchParams.set('condition', data['search']);

		/* */
		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	/** */
	static getAppCertDetail = async (data, mail_flg = false) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/appcert/detail.json");

		/* */
		url.searchParams.set('application_date', data.application_date);
		url.searchParams.set('mail_redirect', mail_flg);

		/* */
		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	static getGuestAppCertDetail = async (token, data = {}) => {
		const uri = API.url + "/guest/appcert/detail.json";

		let url = new URL(uri);
		for (let i in data) {
			url.searchParams.set(i, data[i]);
		}
		// url = url.toString();

		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: token,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	/** */
	static UpdateAppCert = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/appcert/update.json ");

		/* Waiting HTTP-Response before return */
		return await new Promise((resolve, reject) => {

			/* Send HTTP-Request */
			// axios.post(url, JSON.stringify(data), {
			axios.post(url, JSON.stringify(data), {
				headers: {
					Authorization: tokenID,
				},
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});

		});

	}

	/** */
	static SaveAppCert = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/appcert/save.json ");

		/* Waiting HTTP-Response before return */
		return await new Promise((resolve, reject) => {

			/* Send HTTP-Request */
			// axios.post(url, JSON.stringify(data), {
			axios.post(url, JSON.stringify(data), {
				headers: {
					Authorization: tokenID,
				},
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});

		});

	}

	static GuestUpdateAppCert = async (token, data) => {
		const uri = API.url + "/guest/appcert/update.json";

		return await new Promise((resolve, reject) => {
			axios.post(uri, JSON.stringify(data), {
				headers: {
					Authorization: token,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	/** */
	static GuestSaveAppCert = async (token, data) => {
		const uri = API.url + "/guest/appcert/save.json";

		return await new Promise((resolve, reject) => {
			axios.post(uri, data, {
				headers: {
					Authorization: token,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static GuestUploadCert = async (token, file) => {
		const uri = API.url + "/guest/upload";
		// const tokenID = await Auth.GetTokenID();
		// if (!tokenID) {
		// 	return false;
		// }
		const file_type = file.type ?? "binary/octet-stream";
		console.log({file_type});
		const file_ext = file.name.split('.').pop();

		return await new Promise(async (resolve, reject) => {
			axios.post(uri, {
				"file_ext": file_ext,
    			"file_type": file_type,
			}, {
				headers: {
					Authorization: token,
				}
			}).then(resp => {
				const { presignUrl, fileUri } = resp.data.payload;

				axios.put(presignUrl, file, {
					headers: {
					  "Content-Type": file_type,
					}
				}).then(resp => {
					resolve(fileUri);
				}).catch(error => {
					reject(error);
				});
			}).catch(error => {
				reject(error);
			});
		});

	}

	static GuestFileUpload = async (token, file, path = "") => {
		const uri = API.url + "/guest/upload.json";
		// const tokenID = await Auth.GetTokenID();
		// if (!tokenID) {
		// 	return false;
		// }

		return await new Promise(async (resolve, reject) => {
			axios.post(uri, { path }, {
				headers: {
					Authorization: token,
				}
			}).then(resp => {
				let tempInfo = resp.data;
				let tempUrl = tempInfo.url;
				let fields = tempInfo.fields;
				let formData = new FormData();
				for (let i in fields) {
					formData.set(i, fields[i]);
				}
				formData.set("file", file);

				axios.post(tempUrl, formData).then(resp => {
					resolve(tempInfo);
				}).catch(error => {
					reject(error);
				});
			}).catch(error => {
				reject(error);
			});
			// console.log(uploadInfo);
		});

	}

	static GetGuestUserInfo = async (token, data = {}) => {
		const uri = API.url + "/guest/info.json";

		let url = new URL(uri);
		for (let i in data) {
			url.searchParams.set(i, data[i]);
		}

		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: token,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static GetUserGuestInfo = async (data) => {
		const uri = API.url + "/user/guest/info.json";
		const tokenID = await Auth.GetTokenID();
		if (!tokenID) {
			return false;
		}

		let url = new URL(uri);
		for (let i in data) {
			url.searchParams.set(i, data[i]);
		}

		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				},
			}).then((resp) => {
				resolve(resp.data);
			}).catch((error) => {
				reject(error);
			});
		});
	}

	static GetForwardCollabUserInfo = async (data) => {
		const uri = API.url + '/document/forward/user-info.json';
		const tokenID = await Auth.GetTokenID();
		if (!tokenID) {
			return false;
		}

		let url = new URL(uri);
		for (let i in data) {
			url.searchParams.set(i, data[i]);
		}

		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				},
			}).then((resp) => {
				resolve(resp.data);
			}).catch((error) => {
				reject(error);
			});
		});
	}

	static GuestLinkageUserInfo = async (token) => {
		const uri = API.url + "/guest/linkage.json";

		return await new Promise((resolve, reject) => {
			axios.get(uri, {
				headers: {
					Authorization: token,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static GuestLinkageUserApprove = async (token, data) => {
		const uri = API.url + "/guest/linkage.json";

		return await new Promise((resolve, reject) => {
			axios.post(uri, data, {
				headers: {
					Authorization: token,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	/* Guest Approval Action */
	static guestApprovalAction = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		if (data["tenant_id"] == null) {
			data["tenant_id"] = UserInfo["tenant_id"];
		}

		/* */
		const url = new URL(API.url + "/guest/approval-action.json");

		return await new Promise((resolve, reject) => {
			axios.patch(url, data, {
				headers: {
					Authorization: tokenID,
				},
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	static GuestApproverInfo = async (token) => {
		const tokenID = await Auth.GetTokenID();

		if (!tokenID) {
			return false;
		}

		const url = new URL(API.url + "/guest/approver/info.json");

		url.searchParams.set('token', token);

		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static GuestRegistrationApproveReject = async (data) => {
		const tokenID = await Auth.GetTokenID();

		if (!tokenID) {
			return false;
		}

		const url = new URL(API.url + "/guest/approver/confirm.json");

		return await new Promise((resolve, reject) => {
			axios.post(url, data, {
				headers: {
					Authorization: tokenID,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static GuestCollaborationEmailVerify = async (data) => {
		const tokenID = await Auth.GetTokenID();

		if (!tokenID) {
			return false;
		}

		const url = new URL(API.url + "/guest/collaboration/verify.json");

		return await new Promise((resolve, reject) => {
			axios.post(url, data, {
				headers: {
					Authorization: tokenID,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static GuestCollaborationInfo = async (token) => {
		const tokenID = await Auth.GetTokenID();

		if (!tokenID) {
			return false;
		}

		const url = new URL(API.url + "/guest/collaboration/info.json");

		url.searchParams.set('token', token);

		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static GuestRegistrationCollabReject = async (data) => {
		const tokenID = await Auth.GetTokenID();

		if (!tokenID) {
			return false;
		}

		const url = new URL(API.url + "/guest/collaboration/confirm.json");

		return await new Promise((resolve, reject) => {
			axios.post(url, data, {
				headers: {
					Authorization: tokenID,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static TenantCollaborationInfo = async (token) => {
		const tokenID = await Auth.GetTokenID();

		if (!tokenID) {
			return false;
		}

		const url = new URL(API.url + "/guest/collaboration/tenant-info.json");

		url.searchParams.set('token', token);

		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static TenantRegistrationCollabReject = async (data) => {
		const tokenID = await Auth.GetTokenID();

		if (!tokenID) {
			return false;
		}

		const url = new URL(API.url + "/guest/collaboration/tenant-confirm.json");

		return await new Promise((resolve, reject) => {
			axios.post(url, data, {
				headers: {
					Authorization: tokenID,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static ResendGuestApprovalEmail = async (data) => {
		const tokenID = await Auth.GetTokenID();

		if (!tokenID) {
			return false;
		}

		const url = new URL(API.url + "/guest/approver/resend-approval-email.json");

		return await new Promise((resolve, reject) => {
			axios.post(url, data, {
				headers: {
					Authorization: tokenID,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static GuestForwardSign = async (token, data) => {
		const uri = API.url + "/guest/document/forward.json";

		return await new Promise((resolve, reject) => {
			axios.post(uri, data, {
				headers: {
					Authorization: token,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static DocumentLinkage = async (token, data) => {
		const uri = API.url + "/guest/document/linkage.json";

		return await new Promise((resolve, reject) => {
			axios.post(uri, data, {
				headers: {
					Authorization: token,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	/** */
	static DeleteAppCert = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/appcert/delete.json ");

		/* Waiting HTTP-Response before return */
		return await new Promise((resolve, reject) => {

			/* Send HTTP-Request */
			axios.post(url, data, {
				headers: {
					Authorization: tokenID,
				},
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});

		});

	}

	// static appcertEmail = async (data = {}) => {
	static appcertEmail = async (data = {}) => {
		const uri = API.url + "/appcert/sendEmail.json";
		// const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();
		// console.log("data", data);

		return await new Promise((resolve, reject) => {
			axios.post(uri, JSON.stringify(data), {
				headers: {
					Authorization: tokenID,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	/** */
	static getStampList = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/stamp/list.json");

		/* */
		url.searchParams.set('skip', data['skip']);
		url.searchParams.set('take', data['take']);

		/* */
		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	/** */
	static getStampUserGroupList = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/stamp/userGroup.json");

		/* */
		url.searchParams.set('condition', data['search']);

		/* */
		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	/** */
	static getStampDetail = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/stamp/detail.json");

		/* */
		url.searchParams.set('tenant_id', data['tenant_id']);
		url.searchParams.set('group_id', data['group_id']);

		/* */
		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	/** */
	static SaveStamp = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/stamp/save.json ");

		/* Waiting HTTP-Response before return */
		return await new Promise((resolve, reject) => {

			/* Send HTTP-Request */
			// axios.post(url, JSON.stringify(data), {
			axios.post(url, JSON.stringify(data), {
				headers: {
					Authorization: tokenID,
				},
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});

		});

	}

	/** */
	static UpdateStamp = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/stamp/update.json ");

		/* Waiting HTTP-Response before return */
		return await new Promise((resolve, reject) => {

			/* Send HTTP-Request */
			// axios.post(url, JSON.stringify(data), {
			axios.post(url, JSON.stringify(data), {
				headers: {
					Authorization: tokenID,
				},
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});

		});

	}

	/** */
	static DeleteStamp = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/stamp/delete.json ");

		/* Waiting HTTP-Response before return */
		return await new Promise((resolve, reject) => {

			/* Send HTTP-Request */
			// axios.post(url, JSON.stringify(data), {
			axios.post(url, JSON.stringify(data), {
				headers: {
					Authorization: tokenID,
				},
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});

		});

	}

	/** */
	static getTenantList = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/tenants/list.json");

		/* */
		url.searchParams.set('tenant_id', data['tenant_id']);
		url.searchParams.set('status', data['status']);
		url.searchParams.set('sorting_code', data['sorting_code']);
		url.searchParams.set('skip', data['skip']);
		url.searchParams.set('take', data['take']);

		/* */
		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	/** */
	static getTenantServiceList = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/tenants/serviceList.json");

		/* */
		url.searchParams.set('tenant_id', data['tenant_id']);

		/* */
		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	/** */
	static GetListTenantCollaboration = async (data) => {
		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/user/host/collab-tenants.json");

		url.searchParams.set('user_id', data['user_id']);

		/* Waiting HTTP-Response before return */
		return await new Promise((resolve, reject) => {

			/* Send HTTP-Request */
			// axios.post(url, JSON.stringify(data), {
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				},
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});

		});
	}

	/** */
	static SaveTenant = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/tenants/save.json ");

		/* Waiting HTTP-Response before return */
		return await new Promise((resolve, reject) => {

			/* Send HTTP-Request */
			// axios.post(url, JSON.stringify(data), {
			axios.post(url, JSON.stringify(data), {
				headers: {
					Authorization: tokenID,
				},
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});

		});
	}

	static getTenant = async (data) => {
		/* */
		const UserInfo = userInfoState.get({ noproxy: true });
		const tokenID = await Auth.GetTokenID();
		// console.log('tokenID', tokenID, UserInfo)

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		const uri = new URL(API.url + "/tenants/info.json");

		/* Waiting HTTP-Response before return */
		return await new Promise((resolve, reject) => {
			let queryParams = new URLSearchParams();
			queryParams.set("id", data.tenant_id);
			let url = uri + "?" + queryParams.toString();
			/* Send HTTP-Request */
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				},
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});

		});
	}

	/** */
	static UpdateTenant = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/tenants/update.json ");

		/* Waiting HTTP-Response before return */
		return await new Promise((resolve, reject) => {

			/* Send HTTP-Request */
			// axios.post(url, JSON.stringify(data), {
			axios.post(url, JSON.stringify(data), {
				headers: {
					Authorization: tokenID,
				},
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});

		});
	}

	/** */
	static DeleteTenant = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/tenants/delete.json ");

		/* Waiting HTTP-Response before return */
		return await new Promise((resolve, reject) => {

			/* Send HTTP-Request */
			// axios.post(url, JSON.stringify(data), {
			axios.post(url, JSON.stringify(data), {
				headers: {
					Authorization: tokenID,
				},
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});

		});
	}

	/** */
	static TenantSwitch = async (data) => {

		/* */
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!tokenID) {
			return false;
		}

		/* */
		// const url = new URL(API.url + "/tenants/switch.json");
		const url = new URL(API.url + "/tenants/new-switch.json");

		/* Waiting HTTP-Response before return */
		return await new Promise((resolve, reject) => {

			/* Send HTTP-Request */
			axios.post(url, data, {
				headers: {
					Authorization: tokenID,
				},
			}).then(async resp => {
				localStorage.removeItem("defaultVerification");
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});

		});
	}

	/** */
	static getCounterPartyList = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/counter/list.json");

		/* */
		url.searchParams.set('status', data['status']);
		url.searchParams.set('search', data['search']);
		url.searchParams.set('skip', data['skip']);
		url.searchParams.set('take', data['take']);

		/* */
		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	/* Insert/Update GuestUser */
	static setCounterParty = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		if (data["tenant_id"] == null) {
			data["company_id"] = UserInfo["company_id"];
			data["tenant_id"] = UserInfo["tenant_id"];
		}

		/* */
		const url = new URL(API.url + "/counter/save.json");

		/* Waiting HTTP-Response before return */
		return await new Promise((resolve, reject) => {

			/* Send HTTP-Request */
			axios.post(url, data, {
				headers: {
					Authorization: tokenID,
				},
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});

		});

	}

	/** */
	static UploadCsvCounterParty = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/counter/csvUpload.json");

		/* */
		return await new Promise((resolve, reject) => {
			axios.post(url, data, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	static getBatchDownloadList = async (data) => {
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		if (!UserInfo || !tokenID) {
			return false;
		}

		if (data['tenant_id'] == null) {
			data['tenant_id'] = UserInfo['tenant_id'];
		}

		const url = new URL(API.url + "/batch-download/list");

		url.searchParams.set("user_id", UserInfo.user_id);
		url.searchParams.set('page_size', data['page_size']);
		url.searchParams.set('page_number', data['page_number']);

		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static getBatchDownloadDetail = async (data) => {
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		if (!UserInfo || !tokenID) {
			return false;
		}

		const url = new URL(API.url + "/batch-download/detail");

		url.searchParams.set('batch_id', data['batch_id']);

		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static createBatchDownload = async (data) => {
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		if (!UserInfo || !tokenID) {
			return false;
		}

		const url = new URL(API.domain_new + "/api/v2/batch-download/create");

		if (data['tenant_id'] == null) {
			data['tenant_id'] = UserInfo['tenant_id'];
		}

		if (data['user_id'] == null) {
			data['user_id'] = UserInfo['user_id'];
		}

		return await new Promise((resolve, reject) => {
			axios.post(url, data, {
				headers: {
					Authorization: tokenID,
				},
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static getDownloadBatchUrl = async (data) => {
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		if (!UserInfo || !tokenID) {
			return false;
		}

		const url = new URL(API.domain_new + "/api/v2/batch-download/download");

		return await new Promise((resolve, reject) => {
			axios.post(url, data, {
				headers: {
					Authorization: tokenID,
				},
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static oldDownloadBatch = async (data) => {
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		if (!UserInfo || !tokenID) {
			return false;
		}

		const url = new URL(API.domain_new + "/api/v2/batch-download/download");

		if (data['tenant_id'] == null) {
			data['tenant_id'] = UserInfo['tenant_id'];
		}

		let downloadKeyPart = data['download_key'].split('/');
		let fileName = downloadKeyPart[downloadKeyPart.length - 1];

		function downloadZipFromResponse(response) {
			const blob = new Blob([response], { type: 'application/zip' });
			const link = document.createElement('a');
			link.href = window.URL.createObjectURL(blob);
			link.style.display = 'none';
			link.download = fileName;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}

		return await new Promise((resolve, reject) => {
			fetch(url, {
				method: 'POST',
				body: JSON.stringify(data),
				headers: {
					'Content-Type': 'application/json',
					Authorization: tokenID,
				},
			}).then(response => response.blob())
				.then(blob => {
					downloadZipFromResponse(blob);
					resolve(true);
				})
				.catch(error => {
					console.error('Error fetching or handling the response:', error);
					reject(error);
				});
		});
	}


	static getDocumentStatus = async (data) => {
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		if (!UserInfo || !tokenID) {
			return false;
		}

		const url = new URL(API.url + "/document/status");

		url.searchParams.set('document_id', data['document_id']);

		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static getDocumentStatusForList = async (data) => {
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		let url = new URL(API.url + "/document/list/status");
		url.searchParams.set("tenant_id", UserInfo.tenant_id);
		for (let i in data) {
			let value = data[i];
			if (value instanceof Object) {
				value = JSON.stringify(value);
			}
			url.searchParams.set(i, value);
		}

		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static bulkSignDocuments = async (data) => {
		const uri = `${API.domain_new}/api/batch-signature.json`;
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();
		if (!UserInfo || !tokenID) {
			return false;
		}

		return await new Promise((resolve, reject) => {
			axios.post(uri, {
				"documents": data,
			}, {
				headers: {
					"Accept": "application/json",
					"Content-type": "application/json",
					"Authorization": tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static getAuditLogList = async (data) => {
		const uri = API.url + "/audit-log/list.json";
		const tokenID = await Auth.GetTokenID();

		let url = new URL(uri);

		for (let i in data) {
			let value = data[i];
			if (value instanceof Object) {
				value = JSON.stringify(value);
			}
			url.searchParams.set(i, value);
		}

		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static getAuditLogFilter = async (data) => {
		const uri = API.url + "/audit-log/filter.json";
		const tokenID = await Auth.GetTokenID();

		let url = new URL(uri);

		for (let i in data) {
			let value = data[i];
			if (value instanceof Object) {
				value = JSON.stringify(value);
			}
			url.searchParams.set(i, value);
		}

		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}
}

export default Service;
