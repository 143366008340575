// @ts-nocheck


import {withTranslation} from "react-i18next";

import DocumentStep from '../../Config/Documents';
import Layout from '../../Layout';
import Service from '../../Service';
import Error from '../Error';
// import SystemService from '../../Service/System';
// import Service from '../../Service';
// import PDFViewer from '../Fragments/PDFViewer';
import {ListGroup} from 'react-bootstrap';

import {faAngleDoubleLeft, faAngleLeft, faTimes} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import PeopleIcon from '@mui/icons-material/People';
import {CircularProgress, Drawer} from "@mui/material";
import React, {createRef, useCallback, useEffect, useRef} from "react";
import {getDocumentDetail} from "../../data-access/documents/document.api";
import {userInfoState} from "../../drive-feature/data-access/state";
import ProfileAvatar from '../../Layout/ProfileAvatar';
import AuthService from '../../Service/Auth';
import OrganizationService from "../../Service/Organization";
import InvoiceNumberFields from "../Components/VerificationOCR/invoice-number-field";
import OcrFields from "../Components/VerificationOCR/OcrFields";
import {hookstate, none, useHookstate} from "@hookstate/core";
import {Virtuoso} from "react-virtuoso";
import {debounce, throttle} from "../../drive-feature/utils/hooks";
import Loading from "../Loading";
import { isInitState, valueSearchViewer } from "./ViewerSetting";
import { valueSearchHost } from "./HostSetting";
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { appLoadingShow } from "../../data-access/app.state";
import { valueSearchGuest } from "./GuestSetting";
import logo from "../Assets/img/logo_figure.svg";
import { generatePresignedUrlsForPreview } from "../../drive-feature/data-access/api";
import DrivePreviewDialog from "../../drive-feature/components/drive-dialogs/drive-preview-dialog";
import axios from "axios";
export const editErrorDocumemt = hookstate(null);
export const showPreviewAttachment = hookstate({
	open: false,
	files: [],
	fileData: null,
	data: {},
	fileType: "",
	fileUrl: "",
	fileName: "",
	loading: false,
	row: {},
})
const loadMoreLoadingComp = (props)=> {
	// console.log(props.context.loadMoreLoading && props.context.hasMore);
	if(!props.context.hasMore){
		return <></>;
	}
	return props.context.loadMoreLoading ? (<div className='loading_more_comp'> <CircularProgress size={20}/>
	</div>) :<div className='loading_more_comp'> <CircularProgress size={20}/>
	</div>;
}
export const defaultPageSize = 50;
export const defaultSignerSearch = {
	host: {
		data: {},
		detail: {},
		search: '',
		page_number: 1,
		page_size: defaultPageSize,
		total: 0,
		hasMore: true,
		loading: true,
	},
	"host-user": {
		data: {},
		detail: {},
		search: '',
		page_number: 1,
		page_size: defaultPageSize,
		total: 0,
		hasMore: true,
		loading: true,
	},
	guest: {
		data: {},
		detail: {},
		search: '',
		page_number: 1,
		page_size: defaultPageSize,
		total: 0,
		hasMore: true,
		loading: true,
	},
	viewer: {
		data: {},
		detail: {},
		search: '',
		page_number: 1,
		page_size: defaultPageSize,
		total: 0,
		hasMore: true,
		loading: true,
	},
	"group-signer": {
		data: {},
		detail: {},
		search: '',
		page_number: 1,
		page_size: defaultPageSize,
		total: 0,
		hasMore: true,
		loading: true,
	},
	"group-viewer": {
		data: {},
		detail: {},
		search: '',
		page_number: 1,
		page_size: defaultPageSize,
		total: 0,
		hasMore: true,
		loading: true,
	},
}
export const signerSearch = hookstate(defaultSignerSearch);
export const groupedState = hookstate({})
const Scroller = React.forwardRef(({ style, ...props }, ref) => {
	return <ListGroup {...props} ref={ref} as="ul" className="user-selection-box" ></ListGroup>
})

class Documents extends Layout {
    lastClientY;
    lastClientX;
	containerRef = createRef();

	constructor(props) {
		super(props);

		this.state.openOtpDialog = false;
		this.state.isVerifySoftware = false;
		this.state.isVerifyEmail = false;
		this.state.dragging = false;
		this.state["PDFFiles"] = {};
		this.state.filesSort = [];
		this.state.imprints = {};
		this.state.ocrFound = {};
		this.state.pagesImprints = {};
		this.state.docAttr = [];
		this.state.fileName = null;
		this.state.selectedFile = null;
		this.state.CurrentPDFElement = null;
		this.state.previewScale = 100;
		this.state.userGroupCollapse = {};
		this.state.allHosts = {};
		this.state.allGuests = {};
		this.state.isFileLoading = false;
		this.state.allHostGroups = {};
		this.state.allHostGroupWithSeal = {};
		this.state.allViewUsers = {};
		this.state.allViewGroups = {};
		this.state.availableSigners = {};
		this.state.searchAvailableSigners = {};
		this.state.searchSignerText = "";
		this.state.availableGuests = {};
		this.state.searchAvailableGuests = {};
		this.state.searchGuestsText = "";
		this.state.availableViewerUsers = {};
		this.state.searchAvailableViewerUsers = {};
		this.state.searchViewerText = "";
		this.state.availableViewerGroups = {};
		this.state.searchAvailableViewerGroups = {};
		this.state.searchViewerGroupsText = "";
		this.state.availableHostGroups = {};
		this.state.searchAvailableHostGroups = {};
		this.state.CertificateType = {};
		this.state.AttachFiles = {};
		this.state.counterPartyNameList = [];
		this.state.currentPage = 0;
		this.state.PDFLoadSuccess = false;
		this.state.creationType = props.type || props.match.params.type || "sender";
		this.state.creationMode = props.mode || props.match.params.mode || "single";
		this.state.resizeContainer = null;
		this.state.startX= null;
		this.state.startY= null;
		this.state.startWidth= null;
		this.state.startHeight= null;
		this.state.docInfo = null;
		// this.state.function_type = props.type || props.match.params.type || "sender";
		this.state.document_id = props.document_id || props.match.params.document_id || null;

		// console.log(props);
		this.state.translateKeyPrefix = "common:documents.";
		this.state.DocumentStep = new DocumentStep();
		this.state.processStep = this.state.DocumentStep.getStep(this.state.creationType, this.state.creationMode);
		this.state.processStepNameString = {};
		for (let i in this.state.processStep) {
			let step = this.state.processStep[i];
			this.state.processStepNameString[step.name] = step.page;
			// this.state.pageState[step.name] = {
			// 	completed: false,
			// };
		}
		this.state.processStepIndex = Object.values(this.state.processStepNameString);
		this.state.activePage = this.state.processStepIndex[0];
		this.state.tempPDFPassword = null;
		this.state.keepPDFPassword = null;
		this.state.PageError = false;
		this.state.resizerWidth = null;
		// let firstPageName = Object.keys(this.state.processStepNameString)[0];

		// console.log(this.state.activePage);
		// console.log(this.state.creationType);
		// console.log(props);
		// let initData = this.initialTempFormData();
		this.state.pagesRendered = 0;
		this.state.showLoadingPDFLoad = false;
		this.state.originalFormData = {};
		// this.state.formData = initData.formData;
		this.state.startResize = false;
		// this.state.allHosts = initData.allHosts;
		// this.state.allGuests = initData.allGuests;
		// this.state.availableSigners = initData.availableSigners;
		// this.state.availableGuests = initData.availableGuests;
		// this.state.availableViewers = initData.availableViewers;
		// this.changePage(null, this.state.activePage);

		this.state.PageProps = new this.state.activePage(this);
		this.state.loading = true;
		// this.setLocationHash(this.state.PageProps.name + "-new");

		this.state.Currencies = [];
		this.state.PageData = {};
		this.state.FileboxStorage = [];
		this.state.DocumentSavingLog = [];
		this.state.preservationRequire = [];
		// this.GetPageResources();
		// if (this.state.document_id) {
		this.setLocationHash(this.state.activePage.name);
		// } else {
		// 	this.setLocationHash("new");
		// }
		/* Temporary set fields value */
		this.state.guestFields = {};
		this.state.guestFormData = {};
		this.state.guestFormValidate = {};
		this.state.guestFieldValidate = {};
		this.state.guestMsgValidate = "";
		this.state.guestIsInvalid = {};
		this.state.guestIsValid = {};
		this.state.guestFormValidate = false;
		this.state.organizations = [];
		this.state.approver_list = [];
		this.state.approver_list_pagination = {
			page_number: 1,
			page_size: 1000,
			search: "",
		};
		this.state.approver_list_loading = false;
		this.state.enable_load_more_approver_list = true;
		this.state.approver_last_selection = JSON.parse(localStorage.getItem("approver_last_selection"));

		this.state.fieldApplySuggestion = {
			received_date: null,
			amount: null,
			invoice_number: null,
			counter_party_name: null,
		}
		this.state.suggestionData = {};
		this.state.suggestionCommon = {
			anchorEl: null,
		}
		this.state.hide_suggestion_field = {
		}
		this.state.document_type_by_key = {}
		this.state.isSuggestionLoading = {};
		this.state.invoiceNotExist = {};
		this.state.notFoundAnyInvoice = {};
		this.state.menuContentVerification = {
			invoice_number: InvoiceNumberFields,
			received_date: OcrFields,
			amount: OcrFields,
			counter_party_name: OcrFields
		}
		this.state.current_viewer_type = 'viewer';
		this.state.rerenderPopper = false;
	}

    componentWillUnmount() {
        window.removeEventListener('mouseup', this.mouseUpHandle.bind(this));
        window.removeEventListener('mousemove', this.mouseMoveHandle.bind(this));

		document.removeEventListener("mousemove", this.resize, false);
		document.removeEventListener("mouseup", this.resize, false);
		this.setState({
			startResize: false
		})
		// startResize.set(false);
    }
    generateHTMLOcr = (imprint) => {
        let { scale = 0.8, PDFFile } = this.state;
		// console.log()
		// if(!scale) scale = 1.2;
        // console.log(imprint,PDFFile.pages[imprint.page].view.height,PDFFile.pages[imprint.page].view.height - imprint.top * scale * 2 - imprint.defaultHeight, PDFFile.pages[imprint.page])
        imprint.style = {
            width: (imprint.defaultWidth * scale + 2) + "px",
            height: (imprint.defaultHeight * scale + 2) + "px",
            left: (((imprint.left - 3) * scale)) + "px",
            // top:( (PDFFile.pages[imprint.page].view.height - imprint.top - imprint.defaultHeight) * scale) + "px",
			top: ((imprint.top - 3) * scale) + "px",
            fontSize: (scale * 11) + "px", // Scale / 100 * fontSize
			cursor: 'pointer !important'
        }
        // console.log('imprint', imprint)
		return (
			<div key={imprint.id}
				id={imprint.id}
				data-id={imprint.id}
				data-type={imprint.type}
				data-group={imprint.group}
				data-width={imprint.defaultWidth}
				data-height={imprint.defaultHeight}
				// onDragStart={this.handleDragStart}
				// onMouseDown={this.imprintHandleMouseDown}
				className={imprint.className}
				style={imprint.style}
				onClick={(e)=>{
					let {formData}= this.state;
					formData[imprint.type] = imprint.value;
					// console.log(formData[imprint.type])
					let { requiredField,isInvalid, PDFFile, hide_suggestion_field } = this.state;
						requiredField[imprint.type] = true;
						isInvalid[imprint.type] = false;
					// document.getElementById(type).blur();
					hide_suggestion_field[PDFFile?.hash][imprint.type] = true;
                    this.setState({
						formData: formData,
						requiredField,
						isInvalid,
						hide_suggestion_field}, ()=>{

						document.getElementById(imprint.type)?.checkValidity();
					});
					document.getElementById(imprint.type)?.setAttribute('style',  "")
                    // }
                }}
			>
				{/* <imprint.Icon className={imprint.group + "-" + imprint.type} />
				<span className="label-text">{labelText}</span> */}
			</div>
		)
	}

    mouseUpHandle(e) {
        if (this.state?.dragging) {
        //   this.state.dragging = false;
          this.setState({dragging: false});
        }
    }

    mouseDownHandle(e) {
        // console.log(e)
        if (!this.state?.dragging) {
        //   this.state.dragging = true;
          this.setState({dragging: true});
        //   this.setState(this.state);
          this.lastClientX = e.clientX;
          this.lastClientY = e.clientY;
          e.preventDefault();
        }
    }

	resize(e) {
	  if(!this?.state?.startResize) return;
	  const size = `${ (this.state.startWidth - e.clientX + this.state.startX)}px`;
	  // @ts-ignore
	  const vw = (Math.max(document.documentElement.clientWidth, window.innerWidth || 0) - 256) / 2;
	  //   console.log(e.x);
		// console.log(size)
	  if ((this.state.startWidth - e.clientX + this.state.startX) < vw) {
	   	this.setState({resizerWidth: 'calc((100vw - 256px) / 2)'})
		// width.set("256px");
	  } else {
		this.setState({resizerWidth: size})
		// width.set(size);
	  }
	}

    mouseMoveHandle(e) {
        if (this.state?.dragging) {
            // console.log(this.containerRef)
          this.containerRef.current.scrollLeft -=
            (-this.lastClientX + (this.lastClientX = e.clientX));
          this.containerRef.current.scrollTop -=
            (-this.lastClientY + (this.lastClientY = e.clientY));
        }
    }

	buildImprintProperty = (user, group, type, page, position) => {
		return {
			id: group + "-" + user.id,
			userID: user.id,
			userid: user.id,
			type: type,
			group: group,
			page: page,
			top: position.top,
			left: position.left,
			defaultWidth: position.width,
			defaultHeight: position.height,
			user: user,
		};
	}

	processInitDataResponse = (user_type, response) => {
		signerSearch[user_type].loading.set(false);
		signerSearch[user_type].data.merge(response.data);
		signerSearch[user_type].total.set(response.total);
		if (Object.keys(response.data).length === signerSearch[user_type].page_size.get({noproxy: true})) {
			signerSearch[user_type].hasMore.set(true);
		} else {
			signerSearch[user_type].hasMore.set(false);
		}
	}

	GetPageResources = async () => {
		let {
			formData, originalFormData, pageState,
			creationType, document_id, processStepNameString,
			allHosts, allGuests, allHostGroups, allHostGroupWithSeal,
			availableSigners, searchAvailableSigners, availableGuests, searchAvailableGuests, availableViewerUsers,
			searchAvailableViewerUsers, availableViewerGroups, searchAvailableViewerGroups, availableHostGroups, searchAvailableHostGroups,
			CertificateType, Currencies, imprints, counterPartyNameList, allViewUsers, allViewGroups,
			preservationRequire,
			//  PageError,
		} = this.state;

		let allowType = ["signer", "sender", "circle", "verification"];
		if (!allowType.includes(creationType)) {
			this.setState({ PageError: true });
			return;
		}

		let PageData = await Service.GetPageData(creationType);
		let { hosts, guests, hosts_groups, doc_types, cert_types, counter_party, view_users, view_groups, preservation_require, } = PageData;
		// let Currencies = await SystemService.GetC urrenciesList();

		//Bỏ được 3 đoạn loop này đi
		// for (let i in hosts) {
		// 	let user = hosts[i];
		// 	// console.log(user);
		// 	user.id = user.user_id;
		// 	user.name = `${user.family_name} ${user.first_name}`;
		// 	allHosts[user.id] = user;
		// }
		// for (let i in hosts_groups) {
		// 	let group = hosts_groups[i];
		// 	group.id = group.user_id;
		// 	group.name = group.full_name;
		// 	allHostGroups[group.id] = group;
		// 	if (group.has_company_seal === 1) {
		// 		if (group.members) {
		// 			let memberList = group.members;
		// 			group.members = [];
		// 			for (let id of memberList) {
		// 				group.members.push(allHosts[id]);
		// 			}
		// 		}
		// 		allHostGroupWithSeal[group.id] = group;
		// 	}
		// }
		// console.log('guest#1', formData, )
		// console.log(allHostGroupWithSeal);
		// for (let i in guests) {
		// 	let user = guests[i];
		// 	user.id = user.user_id;
		// 	user.name = `${user.family_name} ${user.first_name}`;
		// 	allGuests[user.id] = user;
		// }
		//Bỏ được 3 đoạn loop này đi


		for (let i in cert_types) {
			let cert = cert_types[i];
			CertificateType[cert.id] = cert;
		}
		for (let i in PageData.currencies) {
			let currency = PageData.currencies[i];
			Currencies.push(currency);
		}
		for (let i in counter_party) {
			let cnt_party = counter_party[i];
			counterPartyNameList.push(cnt_party.counter_party_name);
		}
		for (let i in preservation_require) {
			let cnt_require = preservation_require[i];
			preservationRequire.push(cnt_require.name);
		}
		// for (let i in view_users) {
		// 	let view_user = view_users[i];
		// 	// console.log(user);
		// 	view_user.id = view_user.user_id;
		// 	view_user.name = `${view_user.family_name} ${view_user.first_name}`;
		// 	allViewUsers[view_user.id] = view_user;
		// }
		// for (let i in view_groups) {
		// 	let view_group = view_groups[i];
		// 	view_group.id = view_group.user_id;
		// 	view_group.name = view_group.full_name;
		// 	allViewGroups[view_group.id] = view_group;
		// }

		availableSigners = [];
		availableGuests = [];
		availableViewerUsers = [];
		availableViewerGroups = [];
		availableHostGroups = [];

		let promises = [];

		// promises.push(Service.GetHostGuestViewerInitData(creationType, 1, defaultPageSize, ""));
		this.fetchDataUserList('host', '');
		this.fetchDataUserList('guest', '');
		this.fetchDataUserList('viewer', '');
		this.fetchDataUserList('group-viewer', '');
		this.fetchDataUserList('group-signer', '');
		if (creationType === 'verification') {
			const user_info = userInfoState.get({noproxy:true});

			formData['verify_user'] = +(user_info?.user_id || 0);

			if (
				(localStorage.getItem('defaultVerification') || 0)
				&& +(localStorage.getItem('defaultVerification') || 0) !== formData['verify_user']
			){
				formData['verify_user'] = +(localStorage.getItem('defaultVerification') || 0);
			}

			if (formData['verify_user']) {
				promises.push(Service.GetHostUser(
					creationType,
					1,
					defaultPageSize,
					'',
				));
			}
		}


		isInitState.set(true);
		await Promise.all(promises).then((resp) => {
			// if (resp[0].ok) {
				// let hostResult = resp[0].payload.host
					// , guestResult = resp[0].payload.guest
					// , viewerResult = resp[0].payload.viewer
					// , hostGroupResult = resp[0].payload["group-signer"]
					// , viewerGroupResult = resp[0].payload["group-viewer"]
					// , hostUserResult = resp[0].payload["host-user"];

				// availableSigners = hostResult.data;
				// this.processInitDataResponse('host', hostResult);

				// this.processInitDataResponse('host-user', hostUserResult);


				// availableGuests = guestResult.data;
				// this.processInitDataResponse('guest', guestResult);

				// availableViewerUsers = viewerResult.data;
				// this.processInitDataResponse('viewer', viewerResult);

				// availableHostGroups = hostGroupResult.data;
				// this.processInitDataResponse('group-signer', hostGroupResult);

				// availableViewerGroups = viewerGroupResult.data;
				// this.processInitDataResponse('group-viewer', viewerGroupResult);
			// }

			if (resp && resp[0]?.ok) {
				signerSearch["host-user"].data.set(resp[0].payload);
				signerSearch["host-user"].total.set(prevState => resp[0].total);
				if ((localStorage.getItem('defaultVerification') || 0) &&
					+(localStorage.getItem('defaultVerification') || 0) !== formData['verify_user']){
					formData['verify_user'] = +(localStorage.getItem('defaultVerification') || 0);
				}
				this.setFormData(formData);
			}

		}).catch((e) => {throw e});

		// availableGuests = JSON.parse(JSON.stringify(allGuests));

		// availableViewerUsers = JSON.parse(JSON.stringify(allHosts));
		// availableViewerGroups = JSON.parse(JSON.stringify(allHostGroups));
		// availableViewerUsers = JSON.parse(JSON.stringify(allViewUsers));
		// availableViewerGroups = JSON.parse(JSON.stringify(allViewGroups));
		// availableHostGroups = JSON.parse(JSON.stringify(allHostGroupWithSeal));
		// console.log('availableHostGroups', availableHostGroups, availableSigners)
		// console.log('guest#2', formData)
		// console.log(document_id);
		if (document_id) {
			let docInfo =( await getDocumentDetail(document_id).catch(err => {
				//console.log(err.response);
			}))?.data?.payload;
			let { AttachmentFiles } = this.state;
			if(!AttachmentFiles && !!docInfo?.attachments) {
				Object.keys(docInfo.attachments).map((key) => {
					docInfo.attachments[key].uploadDone = true;
					docInfo.attachments[key].file_type = docInfo.attachments[key].type;
				})
				AttachmentFiles = docInfo.attachments;
				this.setState({AttachmentFiles})
			}

            if (docInfo?.received_date) {
                docInfo.received_date = docInfo.received_date.substring(0, 10);
            }
			if (docInfo === false) {
				this.setState({ PageError: true });
				return;
			}
			if (![0, 5, 17].includes(docInfo.doc_status)) {
				this.setState({ PageError: true });
				return;
			}
			if (docInfo.function_code !== creationType) {
				this.setState({ PageError: true });
				return;
			}

			// Amount Process
			if (docInfo?.amount) {
				let Amount = parseFloat(docInfo?.amount || "0").toString();
				Amount = Amount.split(".");
				Amount[0] = Amount.length > 0 && Amount[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
				let convertAmount = Amount[0];
				if (parseInt(Amount[1]) > 0) {
					convertAmount = Amount.join(".")
				}
				docInfo.amount = convertAmount;
			}

			// if (docInfo) {
			formData.is_revision = docInfo?.doc_status == 17 ? true : false;
			formData.document_type = docInfo.document_type_id;
			formData.preservation_require = docInfo.preservation_require || "";
			formData.preservation_require = docInfo.preservation_require;
			formData.doc_title = docInfo.title;
			formData.invoice_number = docInfo.invoice_number;
			formData.amount = docInfo.amount;
			formData.currency = docInfo.currency;
			formData.shared_folders_path = docInfo.shared_folders_path;
			formData.drive_folder_id = docInfo.drive_folder_id;
            // console.log('resp docInfo', formData );
			formData.sign_level = docInfo.sign_level_code;
			formData.display_imprint = true;
			formData.contract_date = docInfo.contract_date?.substring(0, 10) || "";
			formData.expiry_date = docInfo.expiry_date?.substring(0, 10) || "";
			formData.effective_date = docInfo.effective_date?.substring(0, 10) || "";
			formData.received_date = docInfo.received_date?.substring(0, 10) || "";
			formData.document_create_date = docInfo?.document_create_date?.substring(0, 10) || "";
			formData.counter_party_name = docInfo.counter_party_name || "";
			formData.dateofnoti = docInfo.dateofnoti?.toString() || "";
			formData.select_datenoti1 = docInfo.select_datenoti || "";
			if (creationType === "sender") {
				formData.supplier_counter_party_name = docInfo.counter_party_name || "";
			}
			formData.product = docInfo.product_name || "";
			formData.reference = docInfo.reference || "";
			formData.filebox_shortcut = docInfo.filebox_path || docInfo.shared_folders_path || "";
			formData.use_digital_certificate = docInfo.use_digital_certificate || docInfo.use_digital_cert ? true : false;
			formData.content = docInfo.content || "";
			formData.filesSort = docInfo.files_sort || [];
			formData.doc_attributes = [];
			for (let key in docInfo.attributes) {
				formData.doc_attributes.push({
					key: key,
					value: docInfo.attributes[key],
				});
			}
			formData.signers = {};
			formData.guests = {};
			formData.viewers = {};
			imprints = {};
			const processSignature = (signature, group, signer, imprints) => {
				if (signature.position) {
					const fileKey = signature.file;
					const id = `${group}-${signer.id}`;
					if (!imprints.hasOwnProperty(fileKey)) {
						imprints[fileKey] = {};
					}
					imprints[fileKey][id] = this.buildImprintProperty(signer, group, "signature", signature.page, signature.position);
				}
			};

			for (let signer of docInfo.signers) {
				let group = "signers";
				// console.log('signể', signer.certificate_type_id)
				if (signer.user_type === 1) {
					group = "guests";
					formData.guests[signer.id] = {...signer};
					formData.guests[signer.id].self_certificate = signer.self_certificate ? true : false;
					formData.guests[signer.id].certificate_type = signer.certificate_type_id;
					formData.guests[signer.id].sign_seq = +signer.sign_seq
					// delete availableGuests[signer.id];
				} else if (signer.user_type === 2) {
					group = "group";
				// console.log('signể', signer.certificate_type_id)
					formData.signers[signer.id] = {...signer};
					formData.signers[signer.id].self_certificate = signer.self_certificate ? true : false;
					formData.signers[signer.id].certificate_type = signer.certificate_type_id;
					formData.signers[signer.id].sign_seq = +signer.sign_seq
					// delete availableHostGroups[signer.id];

				} else {
					formData.signers[signer.id] = {...signer}
					formData.signers[signer.id].self_certificate = signer.self_certificate ? true : false;
					formData.signers[signer.id].certificate_type = signer.certificate_type_id;
					formData.signers[signer.id].sign_seq = +signer.sign_seq

					// delete availableSigners[signer.id];
					// formData.use_digital_certificate = signer.self_certificate;

				}
				// console.log(signer.id, signer.sign_details);
				if (signer.sign_details) {
					if (signer.sign_details?.signature) {
						const { signature } = signer.sign_details;
						if (Array.isArray(signature)) {
							signature.forEach(sig => processSignature(sig, group, signer, imprints));
						} else if (typeof signature === 'object' && signature !== null) {
							processSignature(signature, group, signer, imprints);
						}
					}
				}
			}

			if (docInfo.receivers) {
				for (let user of Object.values(docInfo.receivers)) {
					formData.guests[user.id] = {...user};
					if (user.sign_seq) formData.guests[user.id].sign_seq = user.sign_seq
					// delete availableGuests[user.id];
				}
			}

			formData.viewers = {};
			for (let viewer of docInfo.viewers) {
				if (viewer.user_type === 2) {
					formData.viewers[viewer.id] = {...viewer};
					// delete availableViewerGroups[viewer.id];
				} else {
					formData.viewers[viewer.id] = {...viewer};
					// delete availableViewerUsers[viewer.id];
				}
			}
			formData.certificate_type = docInfo.certificate_type || 1;
			formData.company_seal = false;

			// console.log(this.state);
			pageState = {};

			for (let i in processStepNameString) {
				pageState[i] = { completed: true };
			}

			let { PDFFiles, PDFFile, selectedFile, filesSort } = this.state;
			PDFFiles = docInfo.files;
			filesSort = docInfo.files_sort;
			// for (let key in PDFFiles) {
			// 	Service.getDocumentFile(docInfo.document_id, key).then(resp={
			// 		let fileObject = await
			// 		PDFFiles[key].file = fileObject;
			// 	}).catch(err => {
			// 		console.log(err.resonse);
			// 	});
			// }
			if (Object.keys(PDFFiles).length > 0) {
				selectedFile = Object.keys(docInfo.files)[0];
				PDFFile = PDFFiles[selectedFile];
				for (let i in PDFFiles) {
					PDFFiles[i].file = PDFFiles[i].url;
				}
			}


			// imprint[id] = {
			// };
			// console.log(imprints);

			this.setState({ PDFFiles, PDFFile, selectedFile, imprints, filesSort, docInfo });
			// }
			// document_id = null;
			// console.log(formData);
			// console.log(pageState);
		}

		if (!formData.document_type && doc_types.length > 0) {
			formData.document_type = doc_types[0].id;
		}
		if (!formData.currency) {
			formData.currency = "JPY";
		}
		// if (!formData.certificate_type) {
		// 	formData.certificate_type = 1;
		// }
		if (!formData.signers) {
			formData.signers = {};
		}
		if (!formData.guests) {
			formData.guests = {};
		}
		if (!formData.viewers) {
			formData.viewers = {};
		}

		if (creationType === "signer") {
			formData.function_type = 1;
			formData.sign_level = formData.sign_level || this.checkSignLevel(formData.signers);
			formData.use_digital_certificate = true;
			// if (formData.sign_level === 0) {
			// 	formData.use_digital_certificate = false;
			// } else if (formData.sign_level === 2) {
			// 	formData.use_digital_certificate = true;
			// }
		} else if (creationType === "sender") {
			formData.function_type = 2;
		} else if (creationType === "circle") {
			formData.function_type = 3;
			formData.sign_level = formData.sign_level || this.checkSignLevel(formData.signers);
			formData.use_digital_certificate = true;
			// if (formData.sign_level === 0) {
			// 	formData.use_digital_certificate = false;
			// } else if (formData.sign_level === 2) {
			// 	formData.use_digital_certificate = true;
			// }
		} else if (creationType === "verification") {
			formData.function_type = 4;
			if (Object.keys(formData.signers).length > 0) {
				formData.verify_user = Object.keys(formData.signers)[0];
			}
			if (!formData.preservation_require) {
				formData.preservation_require = "2";
			}
		}
		// console.log('guest#3', formData)

		originalFormData = JSON.parse(JSON.stringify(formData));

		searchAvailableSigners = { ...availableSigners };
		searchAvailableGuests = { ...availableGuests };
		searchAvailableViewerUsers = { ...availableViewerUsers };
		searchAvailableViewerGroups = { ...availableViewerGroups };
		searchAvailableHostGroups = { ...availableHostGroups };

		this.setState({ formData, originalFormData, Currencies, counterPartyNameList, preservationRequire, PageData, pageState, loading: false });
		this.setState({ allHosts, allGuests, allHostGroups, CertificateType, allViewUsers, allViewGroups });
		this.setState({ availableSigners, availableGuests, availableViewerUsers, availableViewerGroups, availableHostGroups });
		this.setState({ searchAvailableSigners, searchAvailableGuests, searchAvailableViewerUsers, searchAvailableViewerGroups, searchAvailableHostGroups });
		// console.log(allHosts);
	}

	checkSignLevel = (signers) => {
		let cert_group = [];
		let self_cert = true;
		let users = [];
		for (let id in signers) {
			let signer = signers[id];

			if (signer.self_certificate === false) {
				self_cert = false;
			}

			if (!cert_group.includes(signer.certificate_type)) {
				cert_group.push(signer.certificate_type)
			}

			users.push(id);
		}
		let sign_level = 0; // Soft
		if (self_cert && users.length > 0) {
			sign_level = 2; // Strong
		} else if (cert_group.length > 1) {
			sign_level = 1; // Mixed
		}

		return sign_level;
	}

	initDocTitle(){
		let { creationType, PDFFile, document_type_by_key } = this.state;
		let { formData } = this.state;
		// console.log(creationType)
		if (creationType === "verification") {
			let title = PDFFile?.name?.slice(0, PDFFile?.name?.lastIndexOf('.'));
			if(document_type_by_key[PDFFile.hash]){
				formData.document_type = document_type_by_key[PDFFile.hash];
			}
			// console.log('hello', title, formData.doc_title);
			if (formData.doc_title === "" || !formData?.doc_title || (formData?.doc_title === 'common:documents.document-info.doc-title' && title))  {
				formData.doc_title = title;
				this.updateState((prevState, props) => ({
					formData:{...prevState.formData, doc_title: title }
				}))
				// this.objMain.updateState({formData: {...this.objMain.state.formData, doc_title: title}});
			} else {
				// this.updateState((prevState, props) => ({
				// 	formData:{...prevState.formData}
				// }))
			}
		}
	}

	changePage = (ev, PageClass, cb) => {
		let {fieldApplySuggestion, creationType, formData}= this.state;
		fieldApplySuggestion.received_date = null;
		fieldApplySuggestion.amount = null;
		// console.log('ev', ev)
		fieldApplySuggestion.counter_party_name = null;
		this.setState({ activePage: PageClass });
		let PageProps = new PageClass(this);
		this.setState({ PageProps: PageProps,fieldApplySuggestion, rerenderPopper: true, formData }, async ()=> {
			switch (PageClass.name) {
				case "HostSetting":
					let promises = [];
					this.setState({ formData });
					// signerSearch['host'].loading.set(true);
					// signerSearch['group-signer'].loading.set(true);
					// promises.push(Service.GetHostList(
					// 	creationType,
					// 	1,
					// 	defaultPageSize,
					// ).catch((e) => { throw e }));
					// const {formData} = this.state;
					// if(ev.type !== 'submit'){
					if(valueSearchHost.get().length > 0){
							valueSearchHost.set('');
							this.fetchDataUserList('host', '');
							this.fetchDataUserList('group-signer', '');
					}
					// }
					// promises.push(Service.GetHostGroup(
					// 	creationType,
					// 	1,
					// 	defaultPageSize,
					// ).catch((e) => { throw e }));

					// await Promise.all(promises).then((resp) => {
					// 	if (resp[0].ok) {
					// 		this.processInitDataResponse('host', {
					// 			data: resp[0].payload,
					// 			total: resp[0].total,
					// 		});
					// 	}
					// 	if (resp[1].ok) {
					// 		this.processInitDataResponse('group-signer', {
					// 			data: resp[1].payload,
					// 			total: resp[1].total,
					// 		});
					// 	}
					// }).catch((e) => {})
					break;
				case "GuestSetting":
					// const {formData} = this.state;
					// if(ev.type !== 'submit') {
					if(valueSearchGuest.get().length > 0){
							valueSearchGuest.set('');
							this.fetchDataUserList('guest', '');
					}
					// }
					// signerSearch['guest'].loading.set(true);
					// await Service.GetGuestList(
					// 	1,
					// 	defaultPageSize,
					// ).then((resp) => {
					// 	if (resp.ok) {
					// 		this.processInitDataResponse('guest', {
					// 			data: resp.payload,
					// 			total: resp.total,
					// 		});
					// 	}
					// })
					break;
				case "ViewerSetting":
					// const {formData} = this.state;
					// if(ev.type !== 'submit') {
					if(valueSearchViewer.get().length > 0){
							valueSearchViewer.set('');
							this.fetchDataUserList('viewer', '');
							this.fetchDataUserList('group-viewer', '');
					}
					// }
					// signerSearch['viewer'].loading.set(true);
					// signerSearch['group-viewer'].loading.set(true);
					// viewerPromise.push(Service.GetViewerList(
					// 	creationType,
					// 	1,
					// 	defaultPageSize,
					// ).catch((e) => { throw e }));

					// viewerPromise.push(Service.GetViewerGroup(
					// 	creationType,
					// 	1,
					// 	defaultPageSize,
					// ).catch((e) => { throw e }));

					// await Promise.all(viewerPromise).then((resp) => {
					// 	if (resp[0].ok) {
					// 		this.processInitDataResponse('viewer', {
					// 			data: resp[0].payload,
					// 			total: resp[0].total,
					// 		});
					// 	}
					// 	if (resp[1].ok) {
					// 		this.processInitDataResponse('group-viewer', {
					// 			data: resp[1].payload,
					// 			total: resp[1].total,
					// 		});
					// 	}
					// }).catch((e) => {})
					break;
			}

			if(PageClass.name === 'HostSetting'){

				let {formData,allGuests,docInfo,availableGuests, availableHostGroups, availableSigners, allHosts, allHostGroupWithSeal,creationType,creationMode} = this.state
		// let cert = CertificateType[formData.certificate_type];
		// console.log(formData.certificate_type)
					if(creationType === 'sender' && creationMode === 'batch'){
						formData.use_digital_certificate = localStorage.getItem('prev_use_digital_certificate') === 'true';
						if(formData.use_digital_certificate === false) formData.certificate_type = 1;
						else {
							formData.certificate_type = Number(localStorage.getItem('prev_certificate_type')) || 1
						}
					}

					if (formData.certificate_type == 2) {

						formData.company_seal = true;
						this.selectedLabel = "common:documents.host-setting.responsible-list";
						// formData.signers = {};
						// availableSigners = JSON.parse(JSON.stringify(allHosts));
						// availableHostGroups = JSON.parse(JSON.stringify(allHostGroupWithSeal));
						for (let signer of docInfo?.signers || []) {
							let group = "signers";
							if (signer.user_type === 1) {
								group = "guests";
								formData.guests[signer.id] = {...signer};
								formData.guests[signer.id].self_certificate = signer.self_certificate ? true : false;
								formData.guests[signer.id].certificate_type = signer.certificate_type_id;
								formData.guests[signer.id].sign_seq = +signer.sign_seq;
								// delete availableGuests[signer.id];
							} else if (signer.user_type === 2) {
								group = "group";
								formData.signers[signer.id] = {...signer};
								formData.signers[signer.id].self_certificate = signer.self_certificate ? true : false;
								formData.signers[signer.id].certificate_type = signer.certificate_type_id;
								formData.signers[signer.id].sign_seq = +signer.sign_seq;
								// delete availableHostGroups[signer.id];
							} else {
								formData.signers[signer.id] = {...signer};
								formData.signers[signer.id].self_certificate = signer.self_certificate ? true : false;
								formData.signers[signer.id].certificate_type = signer.certificate_type_id;
								formData.signers[signer.id].sign_seq = +signer.sign_seq;
								// delete availableSigners[signer.id];
								// formData.use_digital_certificate = signer.self_certificate;
							}
							// console.log(signer.id, signer.sign_details);

						}
						// console.log(availableSigners, availableHostGroups)
						// this.updateState({ availableSigners, availableHostGroups });
						// this.a = 2;
					}


					// if (formData.sign_level.toString() === "0") {
					// 	formData.use_digital_certificate = false;
					// 	// this.objMain.state.formData.use_digital_certificate = false;
					// } else if (formData.sign_level.toString() === "2") {
					// 	formData.use_digital_certificate = true;

					// }
			}
			if(PageClass.name === 'DocumentInfo' || PageClass.name === 'DocumentMultipleInfo'){
				this.initDocTitle();
				if(PageClass.name === 'DocumentMultipleInfo'){
					document.getElementById('received_date')?.setAttribute('style',  "border-color: #da6517 !important; background-image: unset !important;")
					document.getElementById('amount')?.setAttribute('style',  "border-color: #da6517 !important; background-image: unset !important;")
					document.getElementById('counter_party_name')?.setAttribute('style',  "border-color: #da6517 !important; background-image: unset !important;")
					this.setState({
						requiredField: {
							received_date: true,
							counter_party_name: true,
							amount: true
						},
						rerenderPopper: false,
						isInvalid: {
							// received_date: false,
							// counter_party_name: true,
							// amount: false
						}
					}, cb ? ()=> cb():()=>{})
				}
			}
		});
		this.setLocationHash(PageProps.name);
	}

	handleGetInitialApproverList = () => {
		let {approver_list, approver_list_pagination, approver_list_loading, enable_load_more_approver_list} = this.state;

		if (!approver_list_loading && enable_load_more_approver_list) {
			this.setState({
				approver_list_loading: true,
			});

			Service.getGuestApproverList(approver_list_pagination).then(resp => {
				const {payload, ok} = resp;

				let new_list = [];

				if (ok) {
					if (payload.length > 0) {
						if (approver_list_pagination.page_number === 1) {
							new_list = [...payload];
						} else {
							new_list = [...approver_list, ...payload];
						}
						this.setState({
							approver_list: new_list,
							approver_list_pagination: approver_list_pagination
						});
					} else if (payload.length === 0) {
						this.setState({enable_load_more_approver_list: false});
					}
				}
			}).catch((error) => {

			}).finally(() => {
				this.setState({approver_list_loading: false});
			});
		}
	};
	handleGetGuestInformationForm = () => {
		const user_info = userInfoState.get({noproxy:true});
		if (user_info?.administrator_role === 0) {
			this.handleGetInitialApproverList();
		}

		/* */
		if (this.state.organizations.length === 0) {
			appLoadingShow.set(true)
			OrganizationService.getList().then((response) => {

				/* */
				let { organizations } = this.state;
				// console.log(response)
				/* */
				organizations = response || [];

				/* */
				this.setState({ organizations });
				appLoadingShow.set(false)

			}).catch((error) => {
				appLoadingShow.set(false)
				console.log("OrganizationService.getList.error", error);
			});
		}
	}

	// componentDidMount() {
	componentDidMountExtends() {
        window.addEventListener('mouseup', this.mouseUpHandle.bind(this));
        window.addEventListener('mousemove', this.mouseMoveHandle.bind(this));
		// console.log("Mount", this.state);
		this.GetPageResources();
		this.handleGetGuestInformationForm();
		let { history, location} = this.props;
		// console.log("this.props", this.props);
		// console.log("history", history);

		if (location?.state?.isEditErrorDocument && editErrorDocumemt.get({noproxy: true})) {
			this.setState({
				...editErrorDocumemt.get({noproxy: true}),
			}, () => {
				editErrorDocumemt.set(null);
			});
		}

		this.unlisten = history.listen((location, action) => {
			// console.log("on route change", location, action);
			let { pageState, processStepNameString } = this.state;
			if (location.hash) {
				let pageName = location.hash.substr(1);
				let PageProps = this.state.activePage;
				if (Object.keys(pageState).includes(pageName)) {
					PageProps = processStepNameString[pageName];
				}
				this.changePage(null, PageProps);
			} else {
				// console.log(history);
				if (history.action === "POP") {
					history.goBack();
				}
				this.unlisten();
			}
		});

		// window.addEventListener("beforeunload", this.onUnload);
		window.onbeforeunload = (ev) => {
			// ev.preventDefault();
			// ev.returnValue = 'Are you sure?';
			return "test";
		};
		// this.getCurrentUserAttributes();

	}

	objMapping = (type) => {
		let objMap = {
			"host": {
				"form": "signers",
				"available": "availableSigners",
				"searchAvailable": "searchAvailableSigners",
				"searchText": "searchSignerText",
			},
			"guest": {
				"form": "guests",
				"available": "availableGuests",
				"searchAvailable": "searchAvailableGuests",
				"searchText": "searchGuestsText",
			},
			"viewer": {
				"form": "viewers",
				"available": "availableViewerUsers",
				"searchAvailable": "searchAvailableViewerUsers",
				"searchText": "searchViewerText",
			},
			"group-viewer": {
				"form": "viewers",
				"available": "availableViewerGroups",
				"searchAvailable": "searchAvailableViewerGroups",
				"searchText": "searchViewerGroupsText",
			},
			"group-signer": {
				"form": "signers",
				"available": "availableHostGroups",
				"searchAvailable": "searchAvailableHostGroups",
			},
		};

		return objMap[type];
	}

	/** */
	assignUser = (ev, user_id, user_type, by_group = false, group_id = null, isGroupDetail = false, single_select) => {
		let { formData, creationType } = this.state;
		console.log('single_select', single_select);
		if(single_select == true){
			if(formData.guests){
				Object.keys(formData.guests).forEach((key) => {
					this.unAssignUser(null, formData.guests[key], user_type)
				})
				formData.guests = {};
				this.setState({ formData });
			}
		}
		// console.log(formData);
		// console.log(user_id, user_type, by_group)
		// let availableUsers = null;

		let keyMap = this.objMapping(user_type);

		// let availableUsers = this.state[keyMap.available];
		// let searchAvailableUsers = this.state[keyMap.searchAvailable];

		let availableUsers = signerSearch[user_type].get({noproxy:true})?.data ?? {};

		if (isGroupDetail) {
			availableUsers = signerSearch[user_type].detail[group_id].get({noproxy:true}).data ?? {};
		}
		// console.log('assiged', signerSearch[user_type].detail[group_id].get({noproxy:true}), by_group);

		// console.log(availableUsers[user_id]);
		availableUsers?.[user_id]?.groups?.forEach(v=>{
			signerSearch[user_type].data?.[v.id]?.group_members_count?.set(v=>  v - 1 < 0 ? 0: v-1);

			// if(!signerSearch[user_type].detail[v.id]?.data?.get({stealth: true, noproxy: true})){
			// 	signerSearch[user_type].detail.merge({[v.id]: {
			// 		data: {
			// 			[user_id]: availableUsers[user_id]
			// 		},
			// 		page_number: 1,
			// 		page_size: defaultPageSize,
			// 		loading: true,
			// 		hasMore: true,
			// 	}});
			// } else {
			// 	signerSearch[user_type].detail[v.id].data?.merge(availableUsers[user_id]);
			// }
			// signerSearch[user_type].detail[v.id].data?.[user_id].set(none);
		})
		const processAddGroup = (groupMembers, total) => {
			// console.log('processAdd', groupMembers)
			for (let i in groupMembers) {
				let member = groupMembers[i];
				formData[keyMap.form][member.id] = member;
				formData[keyMap.form][member.id].self_certificate = formData?.use_digital_certificate;
				formData[keyMap.form][member.id].certificate_type = formData?.use_digital_certificate ? formData.certificate_type : 0;
				// requirement default certificate type of guest in company seal is witness type
				if(user_type === 'guest' && formData?.sign_level.toString() !== "0"){
					formData[keyMap.form][member.id].certificate_type = 0
				}
				formData[keyMap.form][member.id].sign_seq = Object.keys(formData[keyMap.form]).length;
				formData[keyMap.form][member.id].groupDetail = {
					isGroupDetail: true,
					group_id,
				};
				// console.log('member', member)
				if(member?.groups?.length > 0){
					member.groups.forEach((g)=>{
						signerSearch[user_type].data?.[g.id]?.group_members_count?.set(v=>  v - 1 < 0 ? 0: v-1);
					})
				}
				signerSearch[user_type].detail[group_id].data[member.id].set(none);
			}

			this.setState({ formData });
			if(formData.function_type === 4){
				localStorage.setItem('prev_verification_viewer_list', JSON.stringify(formData.viewers))
			}else{
				localStorage.setItem('prev_host_signer_list', JSON.stringify(formData.signers))
				localStorage.setItem('prev_viewer_list', JSON.stringify(formData.viewers))
			}
		}
		if (by_group) {
			localStorage.setItem(`defaultUserGroup${this?.state?.activePage?.name}`, user_id);
			// console.log( availableUsers[i], availableUsers[i].groups)

			const groupMemberCount = availableUsers[group_id].group_members_count;
			const totalMemberCount = signerSearch[user_type]?.detail[group_id]?.total?.get();
			if (!signerSearch[user_type].detail.get({noproxy:true})?.[group_id]?.data
				|| groupMemberCount !== Object.keys(signerSearch[user_type].detail.get({noproxy:true})?.[group_id]?.data ?? {}).length) {
				const handleGetUserResponse = async (resp) => {
					if (resp.ok) {
						signerSearch[user_type]['detail'][group_id].loading.set(() => false);
						await processAddGroup(resp.payload, resp.total);
					}
				}
				const handleGetUserError = (e) => {

				}
				signerSearch[user_type]['detail']?.[group_id]?.loading.set(true);
				// signerSearch[user_type]['detail'].merge({[group_id]: {
				// 		data: {},
				// 		page_number: 1,
				// 		page_size: defaultPageSize,
				// 		show: false,
				// 		loading: true,
				// 		hasMore: false,
				// }});

				switch (user_type) {
					case "host":
						Service.GetHostGroupDetail(
							creationType,
							group_id,
							1,
							// totalMemberCount,
							groupMemberCount,
							"",
						).then(handleGetUserResponse).catch(handleGetUserError)
						break;
					case "guest":
						Service.GetGuestGroupDetail(
							group_id,
							1,
							// totalMemberCount,
							groupMemberCount,
							"",
						).then(handleGetUserResponse).catch(handleGetUserError)
						break;
					case "viewer":
						Service.GetViewerGroupDetail(
							creationType,
							group_id,
							1,
							// totalMemberCount,
							groupMemberCount,
							"",
						).then(handleGetUserResponse).catch(handleGetUserError)
						break;
				}
			} else {
				processAddGroup(signerSearch[user_type].detail[group_id].get({noproxy:true}).data ?? {});
			}


		} else {
			// formData[keyMap.form][user_id] = JSON.parse(JSON.stringify(availableUsers[user_id]));
			formData[keyMap.form][user_id] = availableUsers[user_id];

			formData[keyMap.form][user_id].self_certificate = formData.use_digital_certificate;

			formData[keyMap.form][user_id].certificate_type = formData.use_digital_certificate ? formData.certificate_type : 0;
			// requirement default certificate type of guest in company seal is witness type
			if(user_type === 'guest' && formData?.sign_level.toString() !== "0"){
				formData[keyMap.form][user_id].certificate_type = 0
			}
			formData[keyMap.form][user_id].sign_seq = Object.keys(formData[keyMap.form]).length;
			formData[keyMap.form][user_id].groupDetail = {
				isGroupDetail,
				group_id,
			};
			// delete availableUsers[user_id];
			// delete searchAvailableUsers[user_id];
			signerSearch[user_type].data[user_id].set(none);
			if (isGroupDetail) {
				signerSearch[user_type].detail[group_id].data[user_id].set(none);
			}
		}

		// this.setState({ [keyMap.available]: availableUsers, [keyMap.searchAvailable]: searchAvailableUsers });
		this.setState({ formData });
		if(formData.function_type === 4){
			localStorage.setItem('prev_verification_viewer_list', JSON.stringify(formData.viewers))
		}else{
			localStorage.setItem('prev_host_signer_list', JSON.stringify(formData.signers))
			localStorage.setItem('prev_viewer_list', JSON.stringify(formData.viewers))
		}
	}

	sendMailViewer = (user) => {

		let { formData } = this.state;

		if (!user['send_mail'] || user['send_mail'] == 0) user['send_mail'] = 1
		else user['send_mail'] = 0;

		formData['viewers'][user['user_id']] = user

		this.setState({ formData });
		if(formData.function_type !== 4){
			localStorage.setItem('prev_viewer_list', JSON.stringify(formData.viewers))
		}
	}

	unAssignUser = (ev, user, user_type) => {
		// console.log(user_type, user)
		let { formData, availableHostGroups } = this.state;


		if (user?.user_type === 2) {
			if (user_type === "host") {
				user_type = "group-signer";
			} else if (user_type === "viewer") {
				user_type = "group-viewer";
			}
			//  else {
			// 	user_type = "group";
			// }
		} else {
			if(user?.['members_count'] > 0 && user_type === 'viewer'){
				user_type = "group-viewer";
			}
		}

		let keyMap = this.objMapping(user_type);
		// let availableUsers = signerSearch[user_type].data.get({noproxy:true});
		// let availableUsers = this.state[keyMap.available];
		// let searchAvailableUsers = this.state[keyMap.searchAvailable];
		// if()
		// availableUsers[user.id] = JSON.parse(JSON.stringify(formData[keyMap.form][user.id]));
		let unassigned_seq = formData[keyMap.form][user.id]?.sign_seq;
		// console.log(formData[keyMap.form][user.id]?.groups)
		// availableUsers[user.id] = formData[keyMap.form][user.id];
		if (formData[keyMap.form][user.id]?.groups && formData[keyMap.form][user.id]?.groups.length > 0) {
			formData[keyMap.form][user.id].groups.forEach((item) => {
				// console.log(signerSearch[user_type].detail[item.id].data.get({noproxy:true}))
				if (signerSearch[user_type].detail?.[item.id]?.data) {
					signerSearch[user_type].detail[item.id].data.merge({[user.id]: formData[keyMap.form][user.id]});
				}
				// console.log('item', signerSearch[user_type]?.detail?.[item?.id].get({noproxy: true, stealth: true}), item, signerSearch[user_type].data?.[item.id].get({noproxy: true}))
				if(item.user_type !== 2){
					signerSearch[user_type]?.detail?.[item?.id].group_members_count?.set(v=> {
						if(v < signerSearch[user_type]?.detail?.[item.id]?.total?.get()) {
							return v + 1;
						}
						return v;
					});
					signerSearch[user_type].data?.[item.id]?.group_members_count?.set(v=> {
						if(v < signerSearch[user_type]?.detail?.[item.id]?.total?.get()) {
							return v + 1;
						}
						return v;
					});
				}
				// console.log(signerSearch[user_type].detail[item.id].get({noproxy: true}))
			})

		} else {
			signerSearch[user_type].data.merge({[user.id]: formData[keyMap.form][user.id]});
		}
		// searchAvailableUsers[user.id] = formData[keyMap.form][user.id];
		if(formData.certificate_type == '2') availableHostGroups[user.id] = formData[keyMap.form][user.id];
		delete formData[keyMap.form][user.id];

		Object.keys(formData[keyMap.form]).map(x => {
			if (formData[keyMap.form][x]?.sign_seq > unassigned_seq){
				formData[keyMap.form][x].sign_seq -= 1
			}
		})

		// console.log(availableUsers, searchAvailableUsers);
		// console.log('availableHostGroups', availableHostGroups)
		// this.setState({ [keyMap.available]: availableUsers, [keyMap.searchAvailable]: searchAvailableUsers,  });
		this.setState({ formData });
		if(formData.function_type === 4){
			localStorage.setItem('prev_verification_viewer_list', JSON.stringify(formData.viewers))
		}else{
			localStorage.setItem('prev_host_signer_list', JSON.stringify(formData.signers))
			localStorage.setItem('prev_viewer_list', JSON.stringify(formData.viewers))
		}
	}

	callDefaultOpenGroup(ev, user_type, group_id, setUserGroupCollapse){
		const el2 = document.querySelector(`[data-group="${group_id}"]`);
		if(el2 && !el2.querySelector(".custom-collapse").classList.contains("show")){
			setUserGroupCollapse(ev, user_type, el2);
		}
	}

	UserSelectionBox = (props) => {
		let { user, user_type, info, children, by_group, isGroupDetail, hidden, single_select } = props;
		let dataset = {};
		let { t } = this.props;
		let BtnIcon = faAngleLeft;
		// let by_group = true;
		if (["group-signer", "group-viewer"].includes(user_type)) {
			by_group = false;
			BtnIcon = faAngleDoubleLeft;
			dataset["data-group"] = props.group_id;
		} else if (by_group !== undefined) {
			// by_group = by_group;
			// do nothing
		} else {
			by_group = true;
		}

		if (by_group) {
			BtnIcon = faAngleDoubleLeft;
			dataset["data-group"] = props.group_id;
		}

		let OptionalAction = (props) => { return null; };
		if (props.hasOwnProperty("optional") && props.optional) {
			OptionalAction = props.optional;
		}
		// console.log(props.optional);
		// console.log();
		const callFuncD = useCallback(
			debounce(this.callDefaultOpenGroup, 1000, { leading: true }),
			[]
		);

		useEffect(()=>{
			let dataCol = this.state.userGroupCollapse?.[`defaultUserGroup${this?.state?.activePage?.name}`] || localStorage.getItem(`defaultUserGroup${this?.state?.activePage?.name}`);
			if(`${props.group_id}` === dataCol){
				callFuncD({}, user_type, props.group_id, this.setUserGroupCollapse);
				// clickRef(ref)
			}
		}, [this.state.userGroupCollapse, this?.state?.activePage, props.group_id, user_type]);
		const signerAvailable = useHookstate(signerSearch);

		// console.log("user", user_type, user.id, signerSearch[user_type].detail.get({noproxy: true});
		// console.log("loading asdf", signerSearch[user_type].detail.get({noproxy: true}))

		// faExclamationTriangle
		// console.log('dataset', hidden, dataset)
		return (
			<ListGroup.Item
			as="li" className={`user-info ${hidden ? 'd-none':''}`} {...dataset} >
				<div className="action">
					<button
						type="button"
						disabled={this.state?.formData?.certificate_type == 2 && Object.keys(this.state?.formData?.signers || {}).length > 0 && this.state.PageProps.name === 'HostSetting' || (by_group && single_select)}
						className={`btn-add-user ${this.state?.formData?.certificate_type == 2 && Object.keys(this.state?.formData?.signers || {})?.length > 0 && this.state.PageProps.name === 'HostSetting' ? 'disalbed_stamp' : ''}`}
						onClick={this.state?.formData?.certificate_type == 2 && Object.keys(this.state?.formData?.signers || {}).length > 0 && this.state.PageProps.name === 'HostSetting' ? undefined :
							ev => {
								this.assignUser(ev, user.id, user_type, by_group, props.group_id, isGroupDetail, single_select)
							}}
					>
						{(by_group && signerAvailable[user_type].detail.get({noproxy: true})?.[props.group_id]?.loading)
							? <CircularProgress size={12}/>
							: <FontAwesomeIcon icon={BtnIcon}/>}
					</button>
				</div>
				<div className="info">
					<div
						className={info.class}
						onClick={info.click}
					>
						<div className="user-info-detail">
							<div className="top-info">
								<span>{info.top}</span>
							</div>
							<div className="bottom-info">
								<span>{info.bottom}</span>
							</div>
						</div>
						<div style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
						}}>
							{(user?.user_type === 1 && user?.collaboration_user_id && user?.collaboration_tenant_id) && (
								<div>
									<img className="logo-exist-email" src={logo} alt="icon"></img>
								</div>
							)}

							{(user.user_type == 1 && user.status === 3) && (
								<div
									style={{
										height: "100%",
										display: "flex",
										flexDirection: "row",
										alignItems: "center",
										flex: 1,
									}}
								>
									<p
										className={"approval-pending-label"}
										style={{
											fontSize: 12
										}}
									>
										{t("common:settings.guest.status-approval-pending")}
									</p>
								</div>
							)}
						</div>
						<div className="user-info-optional">
							<OptionalAction user={user}/>
						</div>
					</div>
					{children}
				</div>
			</ListGroup.Item>
		);
	}

	MultipleUserSelectionBox = (props) => {
		let { t } = this.props;
		let { users, user_type, group_id, by_group, optional, isGroupDetail, single_select } = props;
		let { UserSelectionBox } = this;
		let UsersBoxElements = [];
		let {creationType, formData} = this.state;

		const groupSearch = signerSearch[user_type]['detail']?.[group_id]?.search?.get({noproxy:true}) || '';

		const signerAvailable = useHookstate(signerSearch);
		// console.log('Multibox', group_id, users)
		const handleGetUserResponse = (resp) => {
			if (resp.ok) {
				signerAvailable[user_type]['detail'][group_id].data.merge(resp.payload);
				signerAvailable[user_type]['detail'][group_id].loading.set(() => false);
				signerAvailable[user_type]['detail'][group_id].hasMore.set(Object.keys(resp.payload).length === defaultPageSize);
			}
		}
		const handleGetUserError = (e) => {

		}
		const handleGetMoreUser = () => {

			if (!signerAvailable[user_type]['detail'][group_id].hasMore.get() || signerAvailable[user_type]['detail'][group_id].loading.get()) {
				return;
			}

			signerAvailable[user_type]['detail'][group_id].loading.set(true);

			signerAvailable[user_type]['detail'][group_id].page_number.set(prevState => prevState + 1);

			switch (user_type) {
				case "host":
					Service.GetHostGroupDetail(
						creationType,
						group_id,
						signerAvailable[user_type]['detail'].get({noproxy: true})[group_id]?.page_number,
						signerAvailable[user_type]['detail'].get({noproxy: true})[group_id]?.page_size,
						groupSearch,
					).then(handleGetUserResponse).catch(handleGetUserError)
					break;
				case "guest":
					Service.GetGuestGroupDetail(
						group_id,
						signerAvailable[user_type]['detail'].get({noproxy: true})[group_id]?.page_number,
						signerAvailable[user_type]['detail'].get({noproxy: true})[group_id]?.page_size,
						groupSearch,
					).then(handleGetUserResponse).catch(handleGetUserError)
					break;
				case "viewer":
					Service.GetViewerGroupDetail(
						creationType,
						group_id,
						signerAvailable[user_type]['detail'].get({noproxy: true})[group_id]?.page_number,
						signerAvailable[user_type]['detail'].get({noproxy: true})[group_id]?.page_size,
						groupSearch,
					).then(handleGetUserResponse).catch(handleGetUserError)
					break;
			}
		}

		for (let i in users) {
			let user = users[i];
			let info = {
				class: "info-container",
				top: `${user?.name} ${user?.company_name || ""}`,
				bottom: user?.email,
			}

			if (["group-signer", "group-viewer"].includes(user_type)) {
				info.top = `${user.id ?? " "}: ${user?.name ?? " "}`;
				info.bottom = t("common:general.total-members") + " " + user?.members_count;
			}
			// if (group_id !== undefined) {
			// 	info = {
			// 		class: "info-container group",
			// 		top: user.name,
			// 		bottom: t("common:general.total-members") + " " + user.members_count,
			// 	};
			// }

			let userProps = {
				user: user,
				user_type: user_type,
				group_id: group_id,
				optional: optional,
				by_group: by_group,
				info: info,
				isGroupDetail: isGroupDetail,
				single_select: single_select
			}
			UsersBoxElements.push(<UserSelectionBox key={group_id + "-" + i}  {...userProps} />);
		}

		if(signerAvailable.get({noproxy: true})?.[user_type]?.['detail']?.[group_id]?.hasMore){
			UsersBoxElements.push(
				<ListGroup.Item as="li" className="user-info">
					{/* <div class="action">HEhlehe</div> */}
					<div className="info">
						<div className="info-container">
							<div
								className="user-info-detail"
								style={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									backgroundColor: "white",
									cursor: "pointer",
								}}
							>
								<div
									className="top-info"
									style={{
										height: 'auto',
									}}
									onClick={() => {
										handleGetMoreUser();
									}}
								>
									{signerAvailable.get({noproxy: true})?.[user_type]?.['detail']?.[group_id]?.loading
										? <CircularProgress size={20}/>
										: <span>{t("common.general.show_more")}</span>
									}
								</div>
								{/* <div class="bottom-info">
								<span>321s</span>
							</div> */}
							</div>
							{/* <div class="user-info-optional"></div> */}
						</div>
					</div>
				</ListGroup.Item>
			   );
		}
		return UsersBoxElements;
	}

	UserSelectionGroup = (props) => {
		let { group_id, user_type, by_group, optional, rendeUsers, show,isGroupDetail, hasMore, single_select } = props;

		let { MultipleUserSelectionBox,UserSelectionBox } = this;

		const {t} = this.props;
		let userRemain = Object.keys(rendeUsers).length;
		let collapseClass = ["custom-collapse"];
		if (show === true) {
			collapseClass.push("show");
		}

		let userProps = {
			users: rendeUsers,
			user_type: user_type,
			group_id: group_id,
			optional: optional,
			by_group: by_group,
			isGroupDetail: isGroupDetail,
			single_select: single_select
		}
		// console.log('rendeUsers', rendeUsers)

		// console.log(group_id, userRemain)
		return (
			<ListGroup as="ul" className={collapseClass} style={{ height: ((userRemain + (hasMore ? 1 : 0)) * (54 )) + "px" }}>
				<MultipleUserSelectionBox {...userProps}  />
			</ListGroup>
		);
	}

	setUserGroupCollapse = (ev, user_type, parentId) => {
		let {creationType} = this.state;
		console.log('run here')
		let parent = ev?.target?.closest(".user-info") || parentId;
		const groupDetail = signerSearch[user_type]['detail'].get({noproxy:true});
		// let userDetail = signerSearch[user_type]['detail'].get({noproxy:true});
		// console.log('hehe', signerSearch[user_type]['detail']?.[parent.dataset.group].get({noproxy: true}))
		const groupSearch = signerSearch[user_type]['detail']?.[parent.dataset.group]?.search?.get({noproxy:true}) || '';
		if (groupDetail[parent.dataset.group]?.data && groupDetail[parent.dataset.group]?.show) {
			signerSearch[user_type]['detail'][parent.dataset.group].show.set(false);
			return;
		}

		if (groupDetail[parent.dataset.group]?.data
		&& !groupDetail[parent.dataset.group]?.show
		&& Object.keys(groupDetail[parent.dataset.group]?.data).length >= groupDetail[parent.dataset.group].total
		&& !groupDetail[parent.dataset.group].hasMore) {
			signerSearch[user_type]['detail'][parent.dataset.group].show.set(true);
			return;
		}


		if (!signerSearch[user_type]['detail'].get({noproxy:true})[parent.dataset.group]) {
			signerSearch[user_type]['detail'].merge({[parent.dataset.group]: {
					data: {},
					page_number: 1,
					page_size: defaultPageSize,
					show: false,
					loading: true,
					hasMore: false,
					search: groupSearch,
					total: 0,
			}});
		}

		// console.log("signerSearch[user_type]['detail']", signerSearch[user_type]['detail'].get({noproxy:true}))

		const handleGroupDetailResponse = (resp) => {
			if (resp.ok) {
				signerSearch[user_type]['detail'].merge({
					[parent.dataset.group]: {
						data: resp.payload,
						page_number: 1,
						page_size: defaultPageSize,
						show: true,
						hasMore: Object.keys(resp.payload).length === defaultPageSize,
						loading: false,
						search: groupSearch,
						total: signerSearch[user_type]['detail']?.[parent.dataset.group]?.total?.get({stealth: true}) || resp.total
					}}
				);
				if (parent.querySelector(".custom-collapse").classList.contains("show")) {
					localStorage.removeItem(`defaultUserGroup${this?.state?.activePage?.name}`);
					this.setState({ userGroupCollapse: {
							[`defaultUserGroup${this?.state?.activePage?.name}`]: ''
					}});
				} else {
					localStorage.setItem(`defaultUserGroup${this?.state?.activePage?.name}`, parent.dataset.group);
					this.setState({ userGroupCollapse: {
							[`defaultUserGroup${this?.state?.activePage?.name}`]: parseInt(parent.dataset.group)
					}});
				}
			}
		}

		const handleGroupDetailError = (e) => {

		}

		signerSearch[user_type]['detail']?.[parent.dataset.group]?.loading.set(true);
		switch (user_type) {
			case "host":
				Service.GetHostGroupDetail(
					creationType,
					parent.dataset.group,
					groupDetail[parent.dataset.group]?.page_number ?? 1,
					groupDetail[parent.dataset.group]?.page_size ?? defaultPageSize,
					groupSearch,
				).then(handleGroupDetailResponse).catch(handleGroupDetailError)
				break;
			case "guest":
				Service.GetGuestGroupDetail(
					parent.dataset.group,
					groupDetail[parent.dataset.group]?.page_number ?? 1,
					groupDetail[parent.dataset.group]?.page_size ?? defaultPageSize,
					groupSearch,
				).then(handleGroupDetailResponse).catch(handleGroupDetailError)
				break;
			case "viewer":
				Service.GetViewerGroupDetail(
					creationType,
					parent.dataset.group,
					groupDetail[parent.dataset.group]?.page_number ?? 1,
					groupDetail[parent.dataset.group]?.page_size ?? defaultPageSize,
					groupSearch,
				).then(handleGroupDetailResponse).catch(handleGroupDetailError)
				break;
		}


	}
	// a = 1;
	AvailableUser = (props) => {
		let { t } = this.props;
		let { users, user_type, optional, single_select } = props;
		let keyMap = user_type;
		let keyMap2 = this.objMapping(user_type);
		const groupState = useHookstate(signerSearch);
		const groupedSave = useHookstate(groupedState)
		const hostAvailable = useHookstate(signerSearch);
		const loadingMore = useHookstate(false);

		let { userGroupCollapse } = this.state;
		let { UserSelectionGroup, UserSelectionBox } = this;
		// console.log("keyMap", keyMap);
		// let searchAvailableUsers = this.state[keyMap.searchAvailable];
		let { formData, PageProps, creationType } = this.state;
		const localHostSinger = localStorage.getItem('prev_host_signer_list')
		const localViewer = localStorage.getItem('prev_viewer_list')
		const localVerificationViewer = localStorage.getItem('prev_verification_viewer_list')
		if(formData.function_type != 4){
			if(Object.keys(formData?.signers || {}).length === 0 && localHostSinger !== 'undefined'){
				formData.signers = JSON.parse(localHostSinger || '{}');
			}
			if(Object.keys(formData?.viewers || {}).length === 0 && localViewer !== 'undefined'){
				formData.viewers = JSON.parse(localViewer || '{}');
			}
		}else{
			if(Object.keys(formData?.viewers || {}).length === 0 && localVerificationViewer !== 'undefined'){
				formData.viewers = JSON.parse(localVerificationViewer || '{}');
			}
		}

		// console.log('searchAvailableUsers', searchAvailableUsers);
		// if(formData.certificate_type == '2') searchAvailableUsers = this.state.availableHostGroups;
		// if (!searchAvailableUsers) {
		// 	return null;
		// }
		// useEffect(()=>{
		// 	console.log('ok boy', hostAvailable[keyMap].value.loading)
		// 	if(props.user_type && !hostAvailable[keyMap].value.loading){
		// 		// signerSearch[user_type].loading.set(true);
		// 		valueSearchHost.set('');
		// 		// hostAvailable[keyMap].loading.set(true);
		// 		// this.fetchDataUserList(user_type, '');
		// 	}
		// }, [props.user_type, hostAvailable[keyMap]]);
		let strongSign = false;

		if (parseInt(formData.sign_level) === 2 || formData.use_digital_certificate) {
			strongSign = true;
		}

		let defaultUserGroupOpen = localStorage.getItem(`defaultUserGroup${this.state?.activePage?.name}`);

		const processGroupAndUser = (newData) => {
			let data = {...newData};
			let { formData } = this.state;
			let assignedData = formData[keyMap2.form];

			let isGroup = [];
			let noGroup2 = [];
			let isGroupCheck = user_type !== 'group-signer' || user_type !== 'group-viewer' || user_type !== 'group-guest';


			for (let i in data) {
				if (assignedData[i]) {
					continue;
				}
				if (
					keyMap !== "group-signer"
					&& keyMap !== "group-viewer"
					&& data[i].user_type === 2
					&& (data[i]?.group_members_count === 0 && isGroupCheck)
				) {
					continue;
				}

				if (
					strongSign && [1, 2, 3].includes(parseInt(formData.function_type))
					&& parseInt(data[i].user_type) === 0 && PageProps.key === "host-setting"
				) {
					if (
						!data[i].available_certs
						|| !data[i].available_certs.includes(parseInt(formData.certificate_type))
					) {
						continue;
					}
				}

				if (data[i].user_type === 2) {
					// let groupUsers = groupedSave.get({stealth: true, noproxy: true})?.[data[i].user_id];
					// // console.log('data[i].user_id',groupedSave.get({stealth: true, noproxy: true})?.[data[i].user_id]?.filter(a=> assignedData[a]), data[i].user_id,groupedSave.get({stealth: true, noproxy: true})?.[data[i].user_id])
					// if(groupUsers && groupUsers?.length === groupUsers?.filter(a=> assignedData[a])?.length) continue;
					// console.log('groupUsers?.filter(a=> assignedData[a])', data[i].group_members_count, groupUsers, groupUsers?.filter(a=> assignedData[a]))
					let groupProps = {
						user: {...data[i]},
						user_type: user_type,
						group_id: data[i].user_id,
						optional: optional,
						by_group: true,
						info: {
							class: "info-container group",
							click: (ev) => this.setUserGroupCollapse(ev, user_type),
							top: `${data[i].user_id ?? " "}: ${data[i].name ?? " "}`,
							bottom: t("common:general.member-remaining") + ` ${(((strongSign && keyMap === "group-signer" || keyMap === "group-viewer")) ? data[i].members_count : data[i].group_members_count) ?? 0}`,
						},
						single_select: single_select
					}

					let userProps = {
						users: {},
						user_type: user_type,
						group_id: data[i].user_id,
						optional: optional,
						by_group: false,
						show: userGroupCollapse[`defaultUserGroup${this.state?.activePage?.name}`] ? (parseInt(userGroupCollapse[`defaultUserGroup${this.state?.activePage?.name}`] || '-1') === parseInt(data[i].user_id)): parseInt(defaultUserGroupOpen || '-1') === parseInt(data[i].user_id),
						single_select: single_select
					}

					// isGroup.push(searchAvailableUsers[i])

					// console.log('runnn', data[i], !hostAvailable[keyMap].detail.get({noproxy: true, stealth: true})?.[groupProps?.group_id]?.loading,
					// hostAvailable[keyMap].detail.get({noproxy: true, stealth: true})?.[groupProps?.group_id]?.group_members_count
					// )
					if (
						keyMap !== "group-signer"
						&& keyMap !== "group-viewer"
						&& groupProps?.by_group
						&& !hostAvailable[keyMap].detail.get({noproxy: true, stealth: true})?.[groupProps?.group_id]?.loading
						&& (hostAvailable[keyMap].detail.get({noproxy: true, stealth: true})?.[groupProps?.group_id]?.group_members_count === 0 && isGroupCheck
						)
					) {
						continue;
					}

					isGroup.push({
						groupProps,
						userProps,
					});
				} else {
					let userProps = {
						user: {...data[i]},
						user_type: user_type,
						group_id: "no-group",
						optional: optional,
						by_group: false,
						info: {
							top: `${data[i].name} ${(data[i].company_name) ?? "" }`,
							bottom: data[i].email,
							class: "info-container",
						},
						single_select: single_select
					}

					noGroup2.push({
						groupProps: null,
						userProps
					})
				}
			}

			return [...isGroup, ...noGroup2];
		}

		const loadMore = useCallback(async () => {
			if (!signerSearch[keyMap].hasMore.get()) {
				return;
			}

			loadingMore.set(true);

			hostAvailable[keyMap].page_number.set((prevState) => prevState + 1);

			const handleGetListResponse = (data) => {
				if (data.ok) {
					loadingMore.set(false);
					if (Object.keys(data.payload).length === defaultPageSize) {
						signerSearch[keyMap].hasMore.set(true);
					} else {
						signerSearch[keyMap].hasMore.set(false);
					}
					hostAvailable[keyMap].data.set((prevState) => {
						return {
							...prevState,
							...data.payload
						}
					});
				}
			}

			const handleGetListError = (e) => {}

			switch (keyMap) {
				case 'host':
					await Service.GetHostList(
						creationType,
						hostAvailable[keyMap].page_number.get({noproxy: true}),
						hostAvailable[keyMap].page_size.get({noproxy: true})
					).then(handleGetListResponse).catch(handleGetListError);
					break;
				case 'guest':
					await Service.GetGuestList(
						hostAvailable[keyMap].page_number.get({noproxy: true}),
						hostAvailable[keyMap].page_size.get({noproxy: true})
					).then(handleGetListResponse).catch(handleGetListError);
					break;
				case 'viewer':
					await Service.GetViewerList(
						creationType,
						hostAvailable[keyMap].page_number.get({noproxy: true}),
						hostAvailable[keyMap].page_size.get({noproxy: true})
					).then(handleGetListResponse).catch(handleGetListError);
					break;
				case "group-signer":
					Service.GetHostGroup(
						creationType,
						hostAvailable[keyMap].page_number.get({noproxy: true}),
						hostAvailable[keyMap].page_size.get({noproxy: true})
					).then(handleGetListResponse).catch(handleGetListError)
					break;
				case "group-viewer":
					Service.GetViewerGroup(
						creationType,
						hostAvailable[keyMap].page_number.get({noproxy: true}),
						hostAvailable[keyMap].page_size.get({noproxy: true})
					).then(handleGetListResponse).catch(handleGetListError)
					break;
				default:
					break;
			}

		}, [])

		// console.log('runRender')
		if(hostAvailable[keyMap].loading.get({noproxy: true})) return <Loading />;
		if (!Object.keys(hostAvailable[keyMap].data.get({noproxy: true}))?.length) {
			return (
				<div></div>
			);
		}
		let renderData = hostAvailable[keyMap].data.get({noproxy: true});
		//End test
		return hostAvailable[keyMap].loading.get({noproxy: true}) ? (
			<Loading />
		) : (
			<Virtuoso
				components={{
					Scroller,
					Footer: loadMoreLoadingComp
				}}
				context={{
					hasMore: hostAvailable[keyMap].hasMore.get(),
					loadMoreLoading: loadingMore.get({noproxy:true})
				}}
				style={{ height: 'calc(100% - 55px) !important' }}
				data={processGroupAndUser(renderData)}
				endReached={loadMore}
				overscan={100}
				itemContent={(i, group) => {
					// if (group_id !== undefined) {
					// 	info = {
					// 		class: "info-container group",
					// 		top: user.name,
					// 		bottom: t("common:general.total-members") + " " + user.members_count,
					// 	};
					// }
					// let group = groupInfo[i];
					if(group?.groupProps){
						let {formData}= this.state;
						let groupProps = group.groupProps;
						let userProps = group.userProps;
						let {user_type, group_id} = userProps;
						let users = groupState[user_type]['detail'].get({noproxy:true})[group_id]?.data ?? {};
						let assignedData = formData[keyMap2.form];
						let rendeUsers = Object.keys(users).reduce((arr, a)=> {
							// console.log()
							if(!assignedData[a]){
								arr[a] = users[a];
							}
							return arr;
						}, {});
						let isGroupDetail = !!groupState[user_type]['detail'].get({noproxy: true})[group_id];
						let show = groupState[user_type]['detail'].get({noproxy:true})[group_id]?.show ?? false;
						let hasMore = groupState[user_type]['detail'].get({noproxy:true})[group_id]?.hasMore;
						// console.log('hello', groupedSave.get({stealth: true, noproxy: true}), rendeUsers)
						// if(!Object.keys(hostAvailable[keyMap].detail.get({noproxy: true, stealth: true})?.[group?.groupProps.group_id]?.data || {})?.length && show) return null;
						return (
							<UserSelectionBox key={`group_${group.id}`} show={show} {...groupProps}>
								<UserSelectionGroup {...userProps} isGroupDetail={isGroupDetail} hasMore={hasMore} show={show} rendeUsers={rendeUsers}  />
							</UserSelectionBox>
						);
					}
					return <UserSelectionBox key={`user_${group.userProps.user.id}`}  {...group.userProps} />
				}}
				// initialItemCount={hostAvailable[keyMap].total.get({noproxy: true})}
		  	/>
			// <ListGroup as="ul" className="user-selection-box">
			// 	{UserGroupElements}
			// </ListGroup>
		);
	}

	SearchViewerGroupsInputChange = throttle(async (props, e) => {
		let { user_type } = props;

		const {creationType} = this.state;
		signerSearch[user_type].loading.set(true);

		const search = e.target.value;

		await Service.GetViewerGroup(
			creationType,
			1,
			defaultPageSize,
			search
		).then((data) => {
			if (data.ok) {
				if (Object.keys(data.payload).length === defaultPageSize) {
					signerSearch[user_type].hasMore.set(true);
				} else {
					signerSearch[user_type].hasMore.set(false);
				}
				signerSearch[user_type].detail.set({});
				signerSearch[user_type].page_number.set(1);
				signerSearch[user_type].loading.set(false);
				signerSearch[user_type].total.set(data.total);
				signerSearch[user_type].data.set(data.payload);
			}
		}).catch((e) => {});

		// let availableGroups = this.state[keyMap.available];
		// if (e.target.value === '') {
		// 	this.setState({ [keyMap.searchAvailable]: { ...availableGroups }, [keyMap.searchText]: e.target.value });
		// }
		// else {
		// 	let searchAvailableGroups = {};
		// 	for (const key in availableGroups) {
		// 		if (availableGroups[key].name.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1) {
		// 			searchAvailableGroups[key] = availableGroups[key];
		// 		}
		// 	}
		// 	this.setState({ [keyMap.searchAvailable]: { ...searchAvailableGroups }, [keyMap.searchText]: e.target.value });
		// }
	}, 700, {leading: false})

	async fetchDataUserList(user_type, search){
		const {creationType, formData} = this.state;
		let keyMap2 = this.objMapping(user_type);
		const handleGetListResponse = (data) => {
			if (data.ok) {
				if (Object.keys(data.payload).length === defaultPageSize) {
					signerSearch[user_type].hasMore.set(true);
				} else {
					signerSearch[user_type].hasMore.set(false);
				}
				let assignedData = formData[keyMap2.form];
				// console.log('assignedData', Object.keys(assignedData || {})?.length)
				// console.log(assignedData);
				// signerSearch[user_type]['detail']?.[parent.dataset.group];
				// const ids = Object.keys(data.payload);
				// console.log('assignedData', assignedData, signerSearch[user_type].detail.get())
				// signerSearch[user_type]['detail']?.[parent.dataset.group]?.get()
				let newDataTotal = {};
				if(Object.keys(assignedData || {})?.length > 0){
					for (let key in assignedData) {
						let a = assignedData[key];
						if(a?.groups?.length > 0){

							a.groups.forEach((b,index)=>{
								if(!newDataTotal[b?.id]){
									newDataTotal[b.id] = {...data.payload[b.id]}.group_members_count;
								}
								if(!search){
									if(data.payload[b?.id]){
										data.payload[b.id].group_members_count = data.payload[b?.id]?.group_members_count - 1;
									}
									if (data.payload[b?.id]?.group_members_count < 0) data.payload[b.id].group_members_count = 0;
								} else {
									let mailCheck = a.email.toLowerCase();
									let fullNameCheck = a.full_name.toLowerCase();
									let idCheck = `${a.user_id}`;
									let searchLower = search.toLowerCase();
									// console.log(data.payload[b?.id], !mailCheck.includes(searchLower) && !fullNameCheck.includes(searchLower) && !idCheck.includes(searchLower))
									if(mailCheck.includes(searchLower)  || fullNameCheck.includes(searchLower) || idCheck.includes(searchLower)){
										if(data.payload[b?.id]){
											data.payload[b.id].group_members_count = data.payload[b?.id]?.group_members_count - 1;
										}
										if (data.payload[b?.id]?.group_members_count < 0) data.payload[b.id].group_members_count = 0;
									}
								}
								// console.log('helehlelh', signerSearch[user_type].data?.[b.id]?.get({noproxy: true}))

								// data.payload[b.id]?.group_members_count?.set(v =>  v - 1 < 0 ? 0: v - 1);
								// signerSearch[user_type].data?
							});
						}
					}
				}
				// console.log(user_type, data )
				// console.log(newData)
				for (let i in data.payload) {
					const user = data.payload[i];

					signerSearch[user_type].detail.merge({
						[user.id]: {
							data: {},
							search: search,
							total: newDataTotal[user.id] || user.group_members_count,
							group_members_count: user.group_members_count,
							hasMore: false,
							page_number: 1,
							page_size: defaultPageSize
						}
					});
				}
				signerSearch[user_type].page_number.set(1);
				setTimeout(()=>{
					signerSearch[user_type].loading.set(false);
					let defaultUGroup = localStorage.getItem(`defaultUserGroup${this?.state?.activePage?.name}`);
					this.setState({ userGroupCollapse: {
							[`defaultUserGroup${this?.state?.activePage?.name}`]: defaultUGroup || ''
					}});
				}, 100);
				// signerSearch[user_type].loading.set(false);
				signerSearch[user_type].total.set(data.total);
				signerSearch[user_type].data.set(data.payload);
			}
		}

		const handleGetListError = (e) => {
			signerSearch[user_type].loading.set(false);
		}
		signerSearch[user_type].loading.set(true);
		// console.log(user_type)
		switch (user_type) {
			case 'host':
				await Service.GetHostList(
					creationType,
					1,
					defaultPageSize,
					search
				).then(handleGetListResponse).catch(handleGetListError);
				break;
			case 'guest':
				await Service.GetGuestList(
					1,
					defaultPageSize,
					search
				).then(handleGetListResponse).catch(handleGetListError);
				break;
			case 'viewer':
				await Service.GetViewerList(
					creationType,
					1,
					defaultPageSize,
					search
				).then(handleGetListResponse).catch(handleGetListError);
				break;
			case "group-signer":
				Service.GetHostGroup(
					creationType,
					1,
					defaultPageSize,
					search
				).then(handleGetListResponse).catch(handleGetListError)
				break;
			case "group-viewer":
				Service.GetViewerGroup(
						creationType,
						1,
						defaultPageSize,
						search
				).then(handleGetListResponse).catch(handleGetListError)
				break;
			default:
				signerSearch[user_type].loading.set(false);
				break;
		}
	}

	SearchInputChange = throttle(async (props, e) => {
		let {user_type} = props;
		const search = e.target.value;
		groupedState.set({});
		this.fetchDataUserList(user_type, search)
		// let keyMap = this.objMapping(user_type);
		// let availableUsers = this.state[keyMap.available];
		// console.log(keyMap.available);
		// if (e.target.value === '') {
		// 	this.setState({ [keyMap.searchAvailable]: { ...availableUsers }, [keyMap.searchText]: e.target.value });
		// }
		// else {
		// 	let searchAvailableUsers = {};
		// 	for (const key in availableUsers) {
		// 		if (keyMap.available === 'availableGuests' && availableUsers[key].company_name.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1) {
		// 			searchAvailableUsers[key] = availableUsers[key];
		// 			continue;
		// 		}
		// 		if (availableUsers[key]?.email?.indexOf(e.target.value) > -1 || availableUsers[key]?.name?.toLowerCase()?.indexOf(e.target.value.toLowerCase()) > -1) {
		// 			searchAvailableUsers[key] = availableUsers[key];
		// 		} else {
		// 			if (availableUsers[key].groups.length > 0) {
		// 				for (let i = 0; i < availableUsers[key].groups.length; i++) {
		// 					if (availableUsers[key].groups[i].name.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1) {
		// 						if (searchAvailableUsers.hasOwnProperty(key)) {
		// 							searchAvailableUsers[key].groups.push(availableUsers[key].groups[i]);
		// 						} else {
		// 							searchAvailableUsers[key] = { ...availableUsers[key] };
		// 							searchAvailableUsers[key].groups = [];
		// 							searchAvailableUsers[key].groups.push(availableUsers[key].groups[i]);
		// 						}
		// 					}
		// 				}
		// 			}
		// 		}
		// 	}
		// 	this.setState({ [keyMap.searchAvailable]: { ...searchAvailableUsers }, [keyMap.searchText]: e.target.value });
		// }
	}, 1000, { leading: false });

	SelectedUsers = (props) => {
		let { t } = this.props;
		let { creationType } = this.state;

		// let { formData, fields } = this.state;
		let { users, user_type, is_revision } = props;
		console.log(is_revision, 'is_revision');
		
		let objElements = [];
		// let { ProfileAvatar } = this;
		let sort_users = Object.values(users);

		sort_users.sort(function (a, b) {
			return a.sign_seq - b.sign_seq;
		});
		// console.log(users, user_type);
		for (let user of sort_users) {
			let iconProps = {};
			if (user?.user_type === 2) {
				iconProps.icon = PeopleIcon;
			} else if (user?.profile_image) {
				iconProps.avatar = user.profile_image;
			}

			let topInfo = `${user?.name} ${user_type === "guest" ? (user?.company_name || "") : ""}`;
			let bottomInfo = `${user?.email || ""}`;
			if (user?.user_type === 2) {
				bottomInfo = `${t("common:general.total-members")} ${user?.members_count}`;
			}

			let OptionalAction = () => { return null; };
			if (props.hasOwnProperty("optional") && props.optional) {
				OptionalAction = props.optional;
			}

			objElements.push(
				<div key={user?.id} className={`user-info user${user?.user_type}`}>
					<div className="display-avatar">
						<ProfileAvatar {...iconProps} />
					</div>
					<div
						className="user-info-detail"
						// style={{
						// 	width: "50%"
						// }}
					>
						<div className="top-info">
							<span>{topInfo} </span>
						</div>
						<div className="bottom-info">
							<span>{bottomInfo} </span>
						</div>
					</div>
					<div style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
					}}>
						{(user?.user_type === 1 && user?.collaboration_user_id && user?.collaboration_tenant_id) && (
							<div>
								<img className="logo-exist-email" src={logo} alt="icon"></img>
							</div>
						)}
						{(user?.user_type === 1 && user?.status === 3) && (
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
									flex: 1,
								}}
							>
								<p
									className={"approval-pending-label"}
									style={{
										fontSize: 12,
									}}
								>
									{t("common:settings.guest.status-approval-pending")}
								</p>
							</div>
						)}
					</div>

					<div className="user-info-optional">
						<OptionalAction user={user} />
					</div>
					{user_type == 'viewer' && creationType != 'verification' && (
						<div className="receive-mail-viewer">
							<button
								type="button"
								className={user['send_mail'] && user['send_mail'] == 1 ? 'btn-icon-bell-active' : 'btn-icon-bell'}
								onClick={(ev) => { this.sendMailViewer(user) }}
							>
								{user['send_mail'] && user['send_mail'] == 1 ? <NotificationsIcon /> : <NotificationsNoneIcon />}
							</button>
						</div>
					)}
					<div className="remove-user">
						{is_revision ? <></> : <button type="button" className="btn-icon" onClick={(ev) => { this.unAssignUser(ev, user, user_type) }}>
							<FontAwesomeIcon icon={faTimes} />
						</button>}
					</div>
				</div>
			);
		}

		return objElements;

	}

	componentWillUpdate(nextProps, nextState){
		// console.log(nextState.startResize)

		if(!nextState.startResize){
			document.removeEventListener("mousemove", this.resize, false);
			document.removeEventListener("mouseup", this.resize, false);
			// startResize.set(false);
		} else {
			document.removeEventListener("mousemove", this.resize, false);
			document.removeEventListener("mouseup", this.resize, false);
			document.addEventListener("mousemove", this.resize, false);
			document.addEventListener("mouseup", this.resize, false);
		}
	}
	componentDidUpdate(prevProps,prevState) {
		// let { PageProps } = this.state;
		// console.log(this.state);
		// console.log(this.props);
		// document.title = this.props.t(this.state.activePage.title);
		// console.log("componentDidUpdate", prevProps, prevState);
		// console.log(PageProps);
		if(prevState?.activePage?.name === 'DocumentMultipleInfo'
		 && Object.keys(prevState?.refOcr || {}).every(a=> prevState?.refOcr[a]?.current)){
			Object.keys(prevState.refOcr).forEach(a=>{
				let {fieldApplySuggestion, hide_suggestion_field, PDFFile} = this.state;
				if(!hide_suggestion_field[PDFFile?.hash] && PDFFile?.hash){
					hide_suggestion_field[PDFFile?.hash] = {
						received_date: false,
						invoice_number: false,
						amount: false,
						counter_party_name: false
					}
				}
				// console.log('hide_suggestion_field', hide_suggestion_field)
				if(!fieldApplySuggestion[a] && !hide_suggestion_field[PDFFile?.hash][a]) {
					fieldApplySuggestion[a] = prevState.refOcr[a].current;
					// console.log(hide_suggestion_field)
					// console.log(fieldApplySuggestion, hide_suggestion_field)
					this.setState({fieldApplySuggestion, hide_suggestion_field});
				}
				// if(fieldApplySuggestion[a] && hide_suggestion_field[a]){
				// 	fieldApplySuggestion[a] = null;
				// 	this.setState({fieldApplySuggestion});
				// }
			});
			// console.log(this.state?.refOcr)
		}
		// if (PageProps.name === "DocumentPrepare") {
		// let ctrlPreview = document.querySelector(".full-preview .control-preview");
		// let pdfDocument = document.querySelector(".full-preview .react-pdf__Document");
		// if (ctrlPreview && ctrlPreview) {
		// 	// console.log(ctrlPreview.offsetWidth, pdfDocument.offsetWidth);
		// 	if (ctrlPreview.offsetWidth < pdfDocument.offsetWidth) {
		// 		ctrlPreview.style.alignItems = "baseline";
		// 	} else {
		// 		ctrlPreview.style.alignItems = "center";
		// 	}
		// }
		// ctrlPreview.scrollTo(0, 300);
		// }
		// else if (PageProps.name === "Determine") {
		// 	let ctrlPreview = document.querySelector(".file-preview-zone");
		// 	let pdfDocument = document.querySelector(".file-preview-zone .react-pdf__Document");
		// 	let canvasDocument = document.querySelector(".file-preview-zone .react-pdf__Document .pdf-custom-page.active canvas");
		// 	if (pdfDocument && canvasDocument) {
		// 		let minHeight = canvasDocument.offsetHeight / canvasDocument.offsetWidth * pdfDocument.offsetWidth;
		// 		let minWidth = canvasDocument.offsetWidth / canvasDocument.offsetHeight * pdfDocument.offsetHeight;
		// 		// console.log(minHeight, minWidth);
		// 		// if (ctrlPreview.offsetWidth < pdfDocument.offsetWidth) {
		// 		// 	ctrlPreview.style.alignItems = "baseline";
		// 		// } else {
		// 		// 	ctrlPreview.style.alignItems = "center";
		// 		// }
		// 	}
		// }
	}

	async previewAttachment(file) {
		// console.log(file);
		let {AttachmentFiles} = this.state;
		// let url = URL.createObjectURL(file.file);
		if(['application/msword',
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
		'application/vnd.ms-powerpoint',
		'application/vnd.openxmlformats-officedocument.presentationml.presentation',
		'docx', 'doc', 'ppt', 'pptx'].includes(file.type)){
			file.fileTypePreview = 'office';
		}

		if(file.type.startsWith('image/') || ['png', 'jpg', 'gif', 'jpeg', 'svg'].includes(file.type)) {
			file.fileTypePreview = 'image';
		}

		if(file.type.startsWith('application/pdf') || file?.file_type === 'pdf') {
			file.fileTypePreview = 'pdf';
		}

		if(file.type.startsWith('video/mp4') || file.type === 'mp4') {
			file.fileTypePreview = 'mp4';
		}

		if( ['application/vnd.ms-excel', 'application/vnd.ms-excel.sheet.macroEnabled.12',
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet','text/csv', 'xlsx', 'xls' ].includes(file.type)){
			file.fileTypePreview = 'excel';
		}
		let isEdit = false;
		let fileUrlTemp = '';
		let previewFile = {...file};
		if(window.location.pathname.includes('documents/edit') && file?.url){
			isEdit = true;
		}
		showPreviewAttachment.loading.set(true);
        showPreviewAttachment.open.set(true);
		if(file.fileTypePreview === 'office'){
			if(isEdit){
				axios.get(previewFile.url, {responseType: 'blob'}).then(async r=>{
					showPreviewAttachment.loading.set(true);
					const formDataFile = new FormData();
					formDataFile.append('file', r.data, previewFile.name);
					formDataFile.append('ignore_first_page', 'true');
					fileUrlTemp = (await Service.convertFileToPdf(formDataFile))?.payload?.url;
					previewFile.fileTypePreview = 'pdf';
					previewFile.url_preview = fileUrlTemp;
					showPreviewAttachment.fileType.set(previewFile.fileTypePreview);
					showPreviewAttachment.fileName.set(previewFile.name);
					showPreviewAttachment.fileUrl.set(previewFile.url_preview);
					showPreviewAttachment.loading.set(false);
					AttachmentFiles[file.hash].url_preview = previewFile.url_preview;
					AttachmentFiles[file.hash].fileTypePreview = previewFile.url_preview;
				});
			} else {
				if(!previewFile.url_preview) {
					const formDataFile = new FormData();
					formDataFile.append('file', file.file, file.name);
					formDataFile.append('ignore_first_page', 'true');
					fileUrlTemp = (await Service.convertFileToPdf(formDataFile))?.payload?.url;
					previewFile.fileTypePreview = 'pdf';
					previewFile.url_preview = fileUrlTemp;
				}
			}
		} else if(file.fileTypePreview === 'mp4' || file.fileTypePreview === 'image') {
			if(window.location.pathname.includes('documents/edit') && file?.url){
				previewFile.url_preview = file.url;
			} else {
				if(previewFile.url_preview) URL.revokeObjectURL(previewFile.url_preview);
				previewFile.url_preview = URL.createObjectURL(file.file);
			}
		} else {
			let file_name = file.name.split('.');
			let file_tail = `.${file_name[file_name.length - 1]}`;
			if(!previewFile.url_preview && !isEdit) {
				fileUrlTemp = (await generatePresignedUrlsForPreview([{is_copy: true, file_key: file.temp, file_tail }], file_tail === '.pdf' ? undefined: file_tail))?.data?.payload?.[0]?.url;
				previewFile.url_preview = fileUrlTemp;
			} else {
				previewFile.url_preview = file.url;
			}
		}
        showPreviewAttachment.fileType.set(previewFile.fileTypePreview);
        showPreviewAttachment.fileName.set(previewFile.name);
        showPreviewAttachment.fileUrl.set(previewFile.url_preview);
		showPreviewAttachment.loading.set(false);
		AttachmentFiles[file.hash].url_preview = previewFile.url_preview;
		AttachmentFiles[file.hash].fileTypePreview = previewFile.url_preview;
		// console.log(file);

		this.setState({AttachmentFiles});
		// console.log(new Excel())
	}

	moveToFile(index){
		let { PDFFile, PDFFiles, formData, originalFormData } = this.state;

		PDFFile = PDFFiles[Object.keys(PDFFiles)[index]]
		if (PDFFile) {
			formData = JSON.parse(JSON.stringify(PDFFile.formData || originalFormData))
			if (formData.doc_title === '') formData.doc_title = PDFFile.name.slice(0, PDFFile.name.lastIndexOf('.'));

			PDFFile.formData = formData;

			this.setState({PDFFile, formData})
		}
	}

	prevFile(){
		let { PDFFile, PDFFiles, formData, originalFormData } = this.state;
		let file_index = Object.keys(PDFFiles).findIndex(x => x === PDFFile.hash)

		if (file_index > 0) file_index -= 1
		PDFFile = PDFFiles[Object.keys(PDFFiles)[file_index]]

		formData = JSON.parse(JSON.stringify(PDFFile.formData || originalFormData))
		if (formData.doc_title === '') formData.doc_title = PDFFile.name.slice(0, PDFFile.name.lastIndexOf('.'));

		PDFFile.formData = formData;

		this.setState({PDFFile, formData})
	}

	nextFile(){
		let { PDFFile, PDFFiles, formData, originalFormData } = this.state;
		let file_index = Object.keys(PDFFiles).findIndex(x => x === PDFFile.hash)

		if (file_index < Object.keys(PDFFiles).length) file_index += 1;
		PDFFile = PDFFiles[Object.keys(PDFFiles)[file_index]]

		formData = JSON.parse(JSON.stringify(PDFFile.formData || originalFormData))
		if (formData.doc_title === '') formData.doc_title = PDFFile.name.slice(0, PDFFile.name.lastIndexOf('.'));

		PDFFile.formData = formData;

		this.setState({PDFFile, formData})
	}

	RenderDialogPreviewAttachment = () => {
		const previewAttach = useHookstate(showPreviewAttachment);
		return (
			<Drawer
				anchor={"right"}
				className="safari_fix_drawer"
				open={previewAttach.open.get({ noproxy: true })}
				onClose={() => {
					previewAttach.open.set(false);
				}}
			>
				<DrivePreviewDialog
					isPreviewAttachment={true}
					handleClose={() => {
						previewAttach.set({
							open: false,
							files: [],
							data: {},
							fileType: "",
							fileUrl: "",
							fileData: null,
							fileName: "",
							loading: false,
							row: {},
						});
					}}
					fileData={previewAttach.fileData.get({ noproxy: true })}
					open={previewAttach.open.get({ noproxy: true })}
					files={previewAttach.files.get({ noproxy: true })}
					fileUrl={previewAttach.fileUrl.get({ noproxy: true })}
					row={previewAttach.row.get({ noproxy: true })}
					fileName={previewAttach.fileName.get({ noproxy: true })}
					fileType={previewAttach.fileType.get({ noproxy: true })}
					loadingFile={previewAttach.loading.get({noproxy: true})}
				/>
			</Drawer>
			);
	}
	Menu = () => {
		let { activePage, processStep, pageState } = this.state;
		let menuKeys = Object.keys(processStep);

		let { t } = this.props;
		let { PDFFiles, PDFFile } = this.state;


		let MenuComponent = menuKeys.map((key) => {
			let Menu = processStep[key];
			// console.log(Menu);
			let pageName = Menu.name;
			let classlist = ["step-child"];
			// console.log(pageState, pageName)
			if (Object.keys(pageState).includes(pageName)) {
				if (pageState[pageName].completed) {
					classlist.push("pass");
				}
			} else {
				classlist.push("disabled");
			}
			if (activePage === Menu.page) {
				classlist.push("active");
			}

			return (
				<li key={key} onClick={(ev) => {
					if (Object.keys(pageState).includes(pageName) && activePage !== Menu.page) {
						this.changePage(ev, Menu.page);
					}
				}} className={classlist.join(" ")}>
					<div className="step-circle">
						<div className="step-circle-dot"></div>
						<div className="step-access-ramp"></div>
					</div>
					<span className="step-label">{t(Menu.text)}</span>
				</li >
			);
		});

		if (activePage?.name === 'DocumentMultipleInfo' ){

			let PDFList = Object.keys(PDFFiles).map((key, index) => {
				let classlist = ["step-child"];

				// if (key === PDFFile.hash) {
				// 	classlist.push("active");
				// }

				let file_index = Object.keys(PDFFiles).findIndex(x => x === PDFFile.hash)

				if (index < file_index){
					classlist.push("pass");
				} else if (index > file_index){
					classlist.push("disabled");
				} else {
					classlist.push("active");
				}

				return (
					<li key={key} onClick={(ev) => {
						if (index !== file_index) {
							this.moveToFile(index)
						}
						// if (index < file_index){
						// 	this.moveToFile(index, file_index);
						// } else if (index > file_index){
						// 	this.nextFile();
						// }

					}} className={classlist.join(" ")}>
						<div className="step-circle">
							<div className="step-circle-dot"></div>
							<div className="step-access-ramp"></div>
						</div>
						<span className="step-label">{t(PDFFiles[key].name)}</span>
					</li >
				);
			});
			MenuComponent.splice(1, 1);
			MenuComponent.splice(1, 0, ...PDFList);
		}

		return (
			<ul className="step-custom step-vertical">
				{MenuComponent}
			</ul>
		);
	}
	async getCurrentUserAttributes() {
		const user = await AuthService.GetCurrentLogin();
		const attributes = user.attributes;
		// console.log(attributes)
		if (attributes['custom:mfa_type'] !== 'disable') {
			// console.log(attributes['custom:mfa_software_auth'].includes('document') && attributes['custom:mfa_type'] === 'software')
			if (attributes['custom:mfa_software_auth']?.includes('document') && attributes['custom:mfa_type'] === 'software') {
				this.setState({
					isVerifySoftware: true
				});
				this.setState({
					openOtpDialog: true,
				});
			}

			if (attributes['custom:mfa_software_auth']?.includes('document') && attributes['custom:mfa_type'] === 'email') {
				this.setState({
					isVerifyEmail: true
				});
				this.setState({
					openOtpDialog: true,
				});
			}
		}
	}
	async handleConfirmOtpDialog(otp) {
		const user = await AuthService.GetCurrentLogin();
		this.setState({
			openOtpDialog: false,
			isVerifySoftware: false
		});
	}

	render() {

		let { PageError, openOtpDialog, isVerifySoftware } = this.state;
		if (PageError) {
			return <Error code={404} />
		}
		// console.log(this.state);
		const { Main } = this;

		return <>
			<Main />
		</>;
	}

}
export default withTranslation()(Documents);
